import { Box, Card, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import { MdVerified } from "react-icons/md";
import { PiPaintBrushFill } from "react-icons/pi";


const Shop = () => {
  return (
    <Box p='10px' bgColor='pink.100'>
      <Heading align='center' mb='10px'>কবুল শপ</Heading>
      <SimpleGrid columns={{ md:3, lg:4}} spacing='10px'>
        <a href='https://shop.bkash.com/kobulco01711572312/pay/bdt99/dCJk7w'>
        <Card p='10px' align='center'>
          <PiPaintBrushFill size='50px' color='#E31B6D'/>
          <Text align='center' mt='10px'><strong>বায়োডাটা লিখন</strong></Text>
          <Text color='grey' mt='10px'>প্রফেশনাল লেখক দ্বারা বায়োডাটা লিখিয়ে নিন</Text>
          <Text color='grey'>** ফোনে করে তথ্য গ্রহন করা হবে</Text>
          <Text mt='10px'>Price: ৯৯ টাকা</Text>
          <Image maxWidth='100px' src='https://firebasestorage.googleapis.com/v0/b/kobul-org.appspot.com/o/static%2Fbkash_payment.png?alt=media&token=42213636-9e10-4481-90c2-7fc710eda382' />
        </Card>
        </a>
        <a href='https://shop.bkash.com/kobulco01711572312/pay/bdt499/9d5tJQ'>
        <Card p='10px' align='center'>
          <MdVerified size='50px' color='#0084FF'/>
          <Text align='center' mt='10px'><strong>কবুল ভেরিফাইড বায়োডাটা</strong></Text>
          <Text color='grey' mt='10px'>আপনার নামের পাশে ভেরিফাইড ব্যাজ যুক্ত করুন</Text>
          <Text color='grey'>** জাতীয় পরিচয়পত্র প্রযোজ্য</Text>
          <Text mt='10px'>Price: ৪৯৯ টাকা</Text>
          <Image maxWidth='100px' src='https://firebasestorage.googleapis.com/v0/b/kobul-org.appspot.com/o/static%2Fbkash_payment.png?alt=media&token=42213636-9e10-4481-90c2-7fc710eda382' />
        </Card>
        </a>
      </SimpleGrid>
    </Box>
  )
}

export default Shop