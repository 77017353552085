import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
import '@fontsource-variable/noto-serif-bengali/wdth.css';
import Header from './components/Header';
import Id from './components/id/Id';
import IdSearch from './components/id/IdSearch';
import MyProfile from './components/profile/MyProfile';
import Home from './components/home/Home';
import MobileAppHome from './components/home/MobileApp'
import Propose from './components/propose/Propose';
import Join from './components/user/Join';
import Login from './components/user/Login';
import ResetPassword from './components/user/ResetPassword';
import Chat from './components/chat/Chat';
import ChatList from './components/chat/ChatList';
import About from './components/others/About';
import Contact from './components/others/Contact';
import Privacy from './components/others/Privacy'
import Shop from './components/shop/Shop';
import DeleteMyData from './components/others/DeleteMyData';
// import MatchMaker from './components/matchmaker/MatchMaker';

const theme = extendTheme({
  fonts: {
    body: "'Noto Serif Bengali Variable', sans-serif",
    heading: "'Noto Serif Bengali Variable', serif",
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Header/>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/app' element={<MobileAppHome />} />
        <Route path='/profile' element={<MyProfile/>} />
        <Route path='/id' element={<IdSearch/>} />
        <Route path='/id/:pid' element={<Id/>} />
        <Route path='/chat' element={<ChatList/>} />
        <Route path='/chat/:pid' element={<Chat/>} />
        <Route path='/propose' element={<Propose />} />
        <Route path='/join' element={<Join />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgotpassword' element={<ResetPassword />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/deletemydata' element={<DeleteMyData />} />
        {/**<Route path='/adminshakir' element={<MatchMaker />} />**/}
      </Routes>
    </ChakraProvider>
  );
}

export default App;
