import { Button, useToast } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/initFirebase';

const DeleteMyData = () => {

  const { currentUser } = useContext(AuthContext);

  const toast = useToast();

  const handleAcDeleteClick = async () => {
    try {
      const Data = {
        uid: currentUser.uid,
      }
      await updateDoc(doc(db, 'deleteAccountRequest', '1'), Data);
      toast({
        title: 'Sent',
        description: "Your request sent successfully, we will contact you soon",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Failed',
        description: "Try Again later", error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }
  return (
    <div>
      <Button m='50px' colorScheme='red' onClick={handleAcDeleteClick}>Request Account  Delete</Button>
    </div>
  )
}

export default DeleteMyData