import React, { useContext, } from 'react';
import { Box, SimpleGrid, Card, Text, Button } from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';
import ManageProfile from './ManageProfile';
import { Helmet } from 'react-helmet';
import LoginScreen from '../utility/LoginScreen'

const Profile = () => {
  const { currentUser, uid } = useContext(AuthContext);

  return (
    <Box align='center' bgColor='pink.100' px='10px' pb='10px'>
      {currentUser ?
        <>
          <SimpleGrid columns={{base:1}} spacing='10px' bgColor='pink.100' >
            <Card justify='center' bgColor='pink.50' p='10px' borderTopRadius='0px'>
              Welcome! {currentUser.displayName}
              <br/>
              Email : {currentUser.email}
            </Card>
          </SimpleGrid>
          { uid ?
            <ManageProfile /> 
           :
           <>
            <Text>আপনার নেট কানেকশনে সমস্যা হয়েছে কিছুক্ষন পর আবার চেষ্টা করুন</Text>
            <Button onClick={() => window.location.reload()} >↻ Reload</Button>
            </>
          }
        </>
        :
        <LoginScreen />
      }
      <Helmet>
        <title>Kobul Profile - Edit and manage your wedding biodata</title>
        <meta name="description" content="You can create and manage your wedding biodata from your kobul profile page" />
      </Helmet>
    </Box>
  );
};

export default Profile;