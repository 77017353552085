import React from 'react'
import { Helmet } from 'react-helmet'

const About = () => {
  return (
    <div>
      <p>Welcome to Kobul, the premier Bangladeshi matrimony website where finding your perfect match is our priority. We understand the importance of shared values, cultural heritage, and aspirations when searching for a life partner.

What Makes Us Different

Deep Understanding of Bangladeshi Culture: Kobul is rooted in Bangladeshi traditions. Our advanced search options let you filter by religion, family background, profession, location, and other factors essential to finding the right match within our culture.
Advanced Matching Technology: Find highly compatible profiles using our intelligent matching algorithm. It suggests matches based on your preferences and personality traits.
Secure and Private: Your safety is paramount. All profiles undergo thorough verification, and you have complete control over who views your information.
Personalized Support: Our dedicated customer support team is always available to assist with your Bangladeshi matrimony journey. We offer personalized guidance and helpful matchmaking advice.
Our Mission

Kobul's mission is to help you find your soulmate. We believe that strong marriages create the foundation for strong families and communities. Our goal is to facilitate meaningful connections that lead to lifelong happiness.

Testimonials

"I was hesitant about online marriage websites, but Kobul changed my mind. They made the process easy and comfortable." - Jahanara, Chittagong
"Kobul's focus on Bangladeshi culture was important to me. I found someone who shares my values and background." - Shafiq, Sylhet
"The support team at Kobul was amazing. Their personalized advice helped me find my perfect match." - Tamim, Dhaka
Call to Action

Start your search for love today! Join Kobul for free and begin exploring profiles of potential life partners in Bangladesh.</p>
      <Helmet>
        <title>Kobul: The Leading Bangladeshi Matrimony Website</title>
        <meta name="description" content="Welcome to Kobul, the premier Bangladeshi matrimony website where finding your perfect match is our priority. We understand the importance of shared values, cultural heritage, and aspirations when searching for a life partner." />
      </Helmet>
    </div>
  )
}

export default About