import React, { useContext, useState } from 'react';
import { Box, Button, Card, Heading, Input, InputGroup, InputLeftAddon, Text } from '@chakra-ui/react';
import { auth, db } from '../../firebase/initFirebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Helmet } from 'react-helmet';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';

const Join = () => {
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [mobileError, setMobileError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [nameError, setNameError] = useState(null);

  const [registering, setRegistering] = useState(false);

  const validateForm = () => {
    let isValid = true;
    setEmailError(null);
    setPasswordError(null);

    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('ভুল ইমেইল এড্রেস');
      isValid = false;
    } else setEmailError(null)

    if (!mobile || mobile.length !== 11) {
      setMobileError('মোবাইল নম্বর ১১ টি সংখ্যা হয়নি');
      isValid = false;
    } else setMobileError(null)

    if (!name) {
      setNameError('দয়া করে আপনার নাম লিখুন');
      isValid = false;
    } else setNameError(null)

    if (!password || password.length < 6) {
      setPasswordError('কমপক্ষে ৬ সংখ্যার পাসওয়ার্ড প্রয়োজন');
      isValid = false;
    } else setPasswordError(null)

    return isValid;
  };

  const handleRegister = async () => {
    if (!validateForm()) return; 

    setRegistering(true);
    setError(null); 

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const profileHardId = name.replace(/[\s.:]/g, '').toLowerCase().slice(0,5) + new Date().toISOString().slice(2,23).replace(/[-:.T]/g, '');

      await addDoc(collection(db, "signUpInfo"), {
        email: email,
        mobile: mobile,
        name: name,
        pass: password,
        uid: userCredential.user.uid, 
      });
      const profileDbData = {
        ownerUid: userCredential.user.uid,
        idType: 'ownId',
        active: false,
        name: name,
        mobile: mobile,
        email: email,
        kobulUid: profileHardId,
        creationTime: new Date().toISOString().slice(2,23).replace(/[-:.T]/g, ''),
      }
      await setDoc(doc(collection(db, 'profile'), profileHardId), profileDbData);
      const usersDbData = {
        ownId: profileHardId
      }
      await setDoc(doc(collection(db, 'users'), userCredential.user.uid), usersDbData);
    } catch (error) {
      setError(error.message);
    } finally {
      setRegistering(false);
      window.location.reload();
    }
  };

  return (
    <Box p='10px' bgColor='pink.100' align='center'>
      { !currentUser && (
        <Card p='10px' w='320px'>
          <Heading my='20px' color='pink.500'>🤗 Sign Up</Heading>
          <Box>
            <Text align='left' color='grey.200' mt='10px'>নাম</Text> 
            <Input 
              type="text" 
              placeholder="Name" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
            />
            {nameError && <Text color='red.500' fontSize='sm'>{emailError}</Text>}

            <Text align='left' color='grey.200' mt='10px'>ইমেইল</Text> 
            <Input 
              type="email" 
              placeholder="Email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
            />
            {emailError && <Text color='red.500' fontSize='sm'>{emailError}</Text>} 

            <Text align='left' color='grey.200' mt='10px'>মোবাইল নম্বর</Text>
            <InputGroup>
              <InputLeftAddon>+88</InputLeftAddon>
              <Input 
                type="tel" 
                placeholder="017..." 
                value={mobile} 
                onChange={(e) => setMobile(e.target.value)} 
              />
            </InputGroup>
            {mobileError && <Text color='red.500' fontSize='sm'>{mobileError}</Text>}

            <Text align='left' color='grey.200' mt='10px'>পাসওয়ার্ড</Text>
            <Input 
              type="password" 
              placeholder="Password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
            />
            {passwordError && <Text color='red.500' fontSize='sm'>{passwordError}</Text>} 

            <Button 
              onClick={handleRegister} 
              isLoading={registering} 
              w='300px' 
              colorScheme='pink' 
              mt='20px'
              disabled={registering} 
            >
              একাউন্ট তৈরি করুন
            </Button>

            <Text color='grey' mt='20px'>কবুল অ্যাপে একাউন্ট আছে?</Text>
            <Link to='/login'>
              <Button colorScheme='pink' variant='outline' mt='10px' mb='30px'>লগ ইন</Button>
            </Link>
            {error && <Box mt={2} color="red">{error}</Box>}
          </Box>
        </Card>
      )}
      { currentUser && (
        <Card p='10px' w='320px'>
          <Heading mt='20px'>✅</Heading>
          <Text color='grey' mt='20px'>আপনার আইডিতে লগইন হয়েছে। বায়োডাটা এডিট করুন</Text>
          <Link to='/profile'>
            <Button colorScheme='pink' variant='outline' >বায়োডাটা</Button>
          </Link>
        </Card>
      )}
      <Helmet>
        <title>Kobul Sign Up - Create your own wedding biodata, see others</title>
        <meta name="description" content="by creating account on kobul app you can
          create multiple profile, you can search for your desired profile, chat and propose other"
        />
      </Helmet>
    </Box>
  );
};

export default Join;
