import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, limit, startAfter } from 'firebase/firestore';
import { db } from '../../firebase/initFirebase'; 
import { Box, SimpleGrid, Text, Image, Card, Table, Tbody, Tr, Td, Button, HStack, Heading, Select, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { districts } from '../data/districts';
import { MdVerified } from 'react-icons/md';

const BiodataCard = () => {
  const [profileData, setProfileData] = useState([]);
  const [lastFetchedDoc, setLastFetchedDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const itemsPerPage = 12;

  const [filteredGender, setFilteredGender] = useState();
  const [filteredPermanentLocation, setFilteredPermanentLocation] = useState();
  const [filteredPresentLocation, setFilteredPresentLocation] = useState();

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setIsLoading(true);
        let filteredQuery = query(collection(db, 'profile'), where('active', '==', true), limit(itemsPerPage));
        if (filteredGender) {
          filteredQuery = query(filteredQuery, where('gender', '==', filteredGender)); 
        }
        if (filteredPermanentLocation) {
          filteredQuery = query(filteredQuery, where('permanentLocation', '==', filteredPermanentLocation)); 
        }
        if (filteredPresentLocation) {
          filteredQuery = query(filteredQuery, where('presentLocation', '==', filteredPresentLocation)); 
        }
        const querySnapshot = await getDocs(filteredQuery); 
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        setProfileData(
          querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setLastFetchedDoc(lastDoc);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadInitialData();
  }, [filteredGender, filteredPermanentLocation, filteredPresentLocation]);

  const loadMoreData = async () => {
    try {
      setIsLoading(true);
      if (!lastFetchedDoc) return; // No more data
      let filteredQuery = query(collection(db, 'profile'), where('active', '==', true), limit(itemsPerPage), startAfter(lastFetchedDoc));

      if (filteredGender) {
        filteredQuery = query(filteredQuery, where('gender', '==', filteredGender));
      }
      if (filteredPermanentLocation) {
        filteredQuery = query(filteredQuery, where('permanentLocation', '==', filteredPermanentLocation)); 
      }
      if (filteredPresentLocation) {
        filteredQuery = query(filteredQuery, where('presentLocation', '==', filteredPresentLocation)); 
      }
      const querySnapshot = await getDocs(filteredQuery);
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setProfileData([
        ...profileData,
        ...querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ]);
      setLastFetchedDoc(lastDoc);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bgColor='pink.100'>
      <Heading as="h2" fontSize="2xl" textAlign="center" py='10px'>
        পাত্র পাত্রীদের প্রফাইল দেখুন
      </Heading>
      <HStack px={{ base: '10px', lg: '100px' }} >
        <VStack spacing={0} bgColor='pink.50'>
          <Text pl='3px' color='grey' pt='2px'>আইডির ধরন</Text>
          <Select size='sm'
            value={filteredGender}
            onChange={(e) => setFilteredGender(e.target.value)}
            _disabled={{ color: 'black', opacity: 1 }}
            bgColor='white'
            iconColor='pink.500'
          >
            <option value="">পাত্র/পাত্রী</option>
            <option value="patri">পাত্রী</option>
            <option value="patro">পাত্র</option>
            <option value="ghotok">ঘটক</option>
          </Select>
        </VStack>
        <VStack spacing={0} bgColor='pink.50'>
          <Text pl='3px' color='grey' pt='2px'>স্থায়ী ঠিকানা</Text>
          <Select size='sm'
            value={filteredPermanentLocation}
            onChange={(e) => setFilteredPermanentLocation(e.target.value)}
            _disabled={{ color: 'black', opacity: 1 }}
            bgColor='white'
            iconColor='pink.500'
          >
            <option value=''>সকল জেলা</option>
            {districts.map((district) => (
              <option value={district} key={district}>{district}</option>
            ))}
          </Select>
        </VStack>
        <VStack spacing={0} bgColor='pink.50'>
          <Text pl='3px' color='grey' pt='2px'>বর্তমান ঠিকানা</Text>
          <Select size='sm'
            value={filteredPresentLocation}
            onChange={(e) => setFilteredPresentLocation(e.target.value)}
            _disabled={{ color: 'black', opacity: 1 }}
            bgColor='white'
            iconColor='pink.500'
          >
            <option value=''>সকল জেলা</option>
            {districts.map((district) => (
              <option value={district} key={district}>{district}</option>
            ))}
          </Select>
        </VStack>
      </HStack>
      <SimpleGrid columns={{ sm: 2, md: 3 }} spacing='10px' py='10px' px={{ base: '10px', lg: '100px' }} alignContent='center'>
        {profileData.map((profile) => (
          <Box key={profile.id}>
            <Link to={`/id/${profile.id}`}>
              <Card p='10px'>
                <Box align='center'>
                  <Image w='100px' h='100px' borderRadius='50px' fit='cover' src={profile.blurredImage || 'https://firebasestorage.googleapis.com/v0/b/kobul-org.appspot.com/o/static%2Favatar.png?alt=media&token=30293073-32a9-4884-953f-f9bb972b43e5'} />
                </Box>
                <Table size='xs' mt='5px'>
                  <Tbody>
                    <Tr>
                      <Td><Text>নামঃ</Text></Td>
                      <Td>
                        <HStack>
                          <Text>{profile.name || ''}</Text>
                          {profile.verified && <MdVerified color='#0084FF' size='17px' />}
                        </HStack>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td><Text>বয়সঃ</Text></Td>
                      <Td><Text>{currentYear - profile.birthYear || ''}</Text></Td>
                    </Tr>
                    <Tr>
                      <Td><Text>পেশাঃ</Text></Td>
                      <Td><Text>{profile.professional?.profession || ''}</Text></Td>
                    </Tr>
                    <Tr>
                      <Td colSpan={2}>
                        <Box align='center'>
                          <Button colorScheme='pink' variant='outline' size='xs'>Full Profile</Button>
                        </Box>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
            </Link>
          </Box>
        ))}
      </SimpleGrid>

      <HStack justifyContent='center' pb='10px'>
        <Button 
          onClick={loadMoreData} 
          disabled={isLoading || !lastFetchedDoc}
          isLoading={isLoading}
          loadingText='Loading'
        >
          Load More
        </Button>
      </HStack>
      <Helmet>
        <title>Kobul - Matrimony matchmaking app</title>
        <meta name="description" content="Create profile(wedding biodata), search profile, chat with each other, send propose, get married, everything inside kobul app" />
      </Helmet>
    </Box>
  );
};

export default BiodataCard;
