import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { db } from '../../firebase/initFirebase';
import {doc, getDoc } from 'firebase/firestore';
import { Button, Image, Text, Box, Heading, SimpleGrid, HStack, Card } from '@chakra-ui/react';
import { FaRegCopy } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { AuthContext } from '../../context/AuthContext';
import PrimaryProposeButton from './PrimaryProposeButton';
import ContactButton from './ContactButton';
import { Helmet } from 'react-helmet';

const DetailRow = ({ label, value }) => {
  return (
    <>
      <HStack justifyContent="space-between" px='10px'>
        <Box w='35%'>
          <Text>{label}</Text>
        </Box>
        <Box w='3%'>
          <Text>:</Text>
        </Box>
        <Box w='60%'>
          <Text>{value}</Text>
        </Box>
      </HStack>
      <hr />
    </>
  );
};

const Id = () => {
  const { uid, currentUser } = useContext(AuthContext);
  const { pid } = useParams();
  const [pData, setPData] = useState(null);
  const [noId, setNoId] =useState(false);

  const currentYear = new Date().getFullYear();
  
  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(`kobul.co/id/${pid}`);
  };

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const docRef = doc(db, 'profile', pid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPData(docSnap.data());
        } else {
          setNoId(true);
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchDoc();
  }, [pid]);

  
  return (
    <Box bgColor='pink.100'>
      {noId && (
        <Box px='20px' py='100px' bgColor='pink.50' minHeight='350px' align='center'>
        <Text p='10px'> দুঃখিত! এই আইডি টি খুজে পাওয়া যায়নি। দয়া করে আইডি পুনরায় চেক করুন।</Text>
        <Link to='/id'>
          <Button m='10px' colorScheme='pink' variant='outline'>
           ← Go Back
          </Button>
        </Link>
        </Box>
      )}
      {pData && (
        <Box p='10px'>
          <SimpleGrid columns={{ md: 2}} spacing='10px'>
            <Box align='center' p='10px' bgColor='pink.50' borderRadius='5px'>
              <Box align='center' h='300px' w='300px'>
                  <Image src={pData.blurredImage}
                  h='300px'w='300px' 
                  borderRadius='10px' alt='Profile Photo'fit='cover'
                  />
              </Box>
              <Box w='300px' align='left'pt='10px'>
                <HStack>
                  <Heading fontSize='20px'>{pData.name}</Heading>
                  {pData.verified && <MdVerified color='#0084FF' size='20px' />}
                </HStack>
                <HStack justify='space-between'>
                  <Text >kobul.co/id/{pid}</Text>
                  <Text >বয়সঃ {currentYear-pData.birthYear}</Text>
                </HStack>
                <HStack justify='space-between' mb='10px'>
                  <Button onClick={copyTextToClipboard} colorScheme='pink' size='sm'>
                    <FaRegCopy/>. Copy
                  </Button>
                  <Link to={`/chat/${pid}`}>
                    <Button colorScheme='pink' size='sm'>💬 Chat</Button>
                  </Link>
                </HStack>

                { currentUser && uid &&
                  <HStack justify='space-between'>
                  <PrimaryProposeButton 
                    senderUid={currentUser.uid} 
                    senderId={uid} 
                    receiverUid={pData.ownerUid} 
                    receiverId={pid}
                  />
                  <ContactButton 
                    senderUid={currentUser.uid} 
                    senderId={uid}
                    receiverUid={pData.ownerUid} 
                    receiverId={pid} 
                  />
                </HStack>}
                  
                  {(!currentUser || !uid) && 
                  <>
                    <HStack justify='space-between'>
                      <Button 
                        isDisabled colorScheme='pink' size='sm'>
                        ছবি দেখার প্রস্তাব
                      </Button>
                      <Button isDisabled colorScheme='pink' size='sm'>
                        সাক্ষাৎ করার প্রস্তাব
                      </Button>
                    </HStack>
                    <Text>ছবি দেখার বা সাক্ষাৎ করার প্রস্তাব পাঠাতে হলে দয়া করে নিজের প্রফাইল তৈরি করুন!!</Text>
                  </>
                  }
                
              </Box>
            </Box>
            <Card p='10px'>
              <Heading alignSelf="center">ব্যাক্তিগত তথ্য</Heading>
              <hr />
              <DetailRow label={'নাম'} value={pData.name} />
              <DetailRow label={'জন্মসন'} value={pData.birthYear} />
              <DetailRow label={'গায়ের রং'} value={pData.skinColor} />
              <DetailRow label={'উচ্চতা'} value={pData.height} />
              <DetailRow label={'ওজন'} value={pData.weight} />
              <DetailRow label={'স্থায়ী ঠিকানা'} value={pData.permanentLocation} />
              <DetailRow label={'বর্তমান ঠিকানা'} value={pData.presentLocation} />
              <DetailRow label={'বৈবাহিক অবস্থা'} value={pData.maritalStatus} />
              <DetailRow label={'রক্তের গ্রুপ'} value={pData.bloodGroup} />
              <DetailRow label={'ধর্ম'} value={pData.religion} />
            </Card>
            <Card p='10px'>
              <Heading alignSelf="center">শিক্ষা ও পেশা</Heading>
              <hr/>
              <DetailRow label={'শিক্ষা'} value={pData.educationDegree} />
              <DetailRow label={'শিক্ষা প্রতিষ্ঠান'} value={pData.educationInstitute} />
              <DetailRow label={'পেশা'} value={pData.profession} />
              <DetailRow label={'পেশার বিবরন'} value={pData.professionDetails} />
              <DetailRow label={'মাসিক ইনকাম'} value={pData.monthlyIncome} />
            </Card>
            <Card p='10px'>
              <Heading alignSelf="center">পারিবারিক তথ্য</Heading>
              <hr />
              <DetailRow label={'পিতা-মাতা'} value={pData.parents} />
              <DetailRow label={'পিতার পেশা'} value={pData.fatherProfession} />
              <DetailRow label={'মাতার পেশা'} value={pData.motherProfession} />
              <DetailRow label={'ভাইদের সংখ্যা'} value={pData.brotherNumber} />
              <DetailRow label={'বোনদের সংখ্যা'} value={pData.sisterNumber} />
              <DetailRow label={'অর্থনৈতিক অবস্থা'} value={pData.financialCondition} />
            </Card>
            <Card p='10px'>
              <Heading alignSelf="center">প্রত্যাশা</Heading>
              <hr />
              <DetailRow label={'বয়স'} value={pData.expectedAge} />
              <DetailRow label={'গায়ের রং'} value={pData.expectedSkinColor} />
              <DetailRow label={'শিক্ষা'} value={pData.expectedEducation} />
              <DetailRow label={'উচ্চতা'} value={pData.expectedHeight} />
              <DetailRow label={'ওজন'} value={pData.expectedWeight} />
              <DetailRow label={'স্থায়ী ঠিকানা'} value={pData.expectedPermanentLocation} />
              <DetailRow label={'বর্তমান ঠিকানা'} value={pData.expectedPresentLocation} />
              <DetailRow label={'বৈবাহিক অবস্থা'} value={pData.expectedMaritalStatus} />
              <DetailRow label={'অন্যান্য'} value={pData.expectedOthers} />
            </Card>
          </SimpleGrid>
        </Box> 
      )}
      <Helmet>
        <title>{`Kobul ID - Wedding Biodata of ${pid}`}</title>
        <meta name="description" content="View wedding biodata, send proposal, search your desired person & create your own biodata for free" />
      </Helmet>
    </Box>
  );
};

export default Id;
