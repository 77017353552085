export const districts = [
  "বাগেরহাট",
  "বান্দরবান",
  "বরগুনা",
  "বরিশাল",
  "ভোলা",
  "বগুড়া",
  "ব্রাহ্মণবাড়িয়া",
  "চাঁদপুর",
  "চাঁপাইনবাবগঞ্জ",
  "চট্টগ্রাম",
  "চুয়াডাঙ্গা",
  "কুমিল্লা",
  "কক্সবাজার",
  "ঢাকা",
  "দিনাজপুর",
  "ফরিদপুর",
  "ফেনী",
  "গাইবান্ধা",
  "গাজীপুর",
  "গোপালগঞ্জ",
  "হবিগঞ্জ",
  "জায়পুরহাট",
  "জামালপুর",
  "যশোর",
  "ঝালকাঠি",
  "ঝিনাইদহ",
  "জয়পুরহাট",
  "খাগড়াছড়ি",
  "খুলনা",
  "কিশোরগঞ্জ",
  "কুড়িগ্রাম",
  "কুষ্টিয়া",
  "লক্ষ্মীপুর",
  "লালমনিরহাট",
  "মাদারীপুর",
  "মাগুরা",
  "মানিকগঞ্জ",
  "মেহেরপুর",
  "মৌলভীবাজার",
  "মুন্সীগঞ্জ",
  "ময়মনসিংহ",
  "নওগাঁ",
  "নড়াইল",
  "নরসিংদী",
  "নাটোর",
  "নেত্রকোণা",
  "নীলফামারী",
  "নোয়াখালী",
  "পঞ্চগড়",
  "পটুয়াখালী",
  "পিরোজপুর",
  "রাজবাড়ী",
  "রাঙ্গামাটি",
  "রংপুর",
  "সাতক্ষীরা",
  "শরীয়তপুর",
  "শেরপুর",
  "সিরাজগঞ্জ",
  "সুনামগঞ্জ",
  "সিলেট",
  "টাঙ্গাইল",
  "ঠাকুরগাঁও"
];
