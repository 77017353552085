import React, { useState } from 'react';
import { Card, Button, Box, Input } from '@chakra-ui/react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase/initFirebase';

const ImageUpload = ({ pid, updateDataToFirebase }) => {
  const [image, setImage] = useState(null);
  const [blurredImage, setBlurredImage] = useState(null);
  const [clicked, setClicked] = useState(false);

  const handleImageChange = async (event) => {
    const selectedImage = event.target.files[0];

    // Resize the image
    const resizedImage = await resizeImage(selectedImage, 600);
    setImage(resizedImage);

    // Blur and resize the image
    const blurredAndResized = await blurAndResizeImage(selectedImage, 600, 20); 
    setBlurredImage(blurredAndResized);
  };

  const resizeImage = (imageFile, maxWidth) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const targetWidth = Math.min(maxWidth, img.width);
          const scaleFactor = targetWidth / img.width;
          const targetHeight = img.height * scaleFactor;

          canvas.width = targetWidth;
          canvas.height = targetHeight;

          ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

          canvas.toBlob((blob) => {
            const resizedImage = new File([blob], imageFile.name, { type: 'image/jpeg' });
            resolve(resizedImage);
          }, 'image/jpeg', 0.8); // Adjust the quality (0.0 - 1.0) as needed
        };
      };

      reader.readAsDataURL(imageFile);
    });
  };

  const blurImage = (imageFile, blurRadius) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = img.width;
          canvas.height = img.height;

          ctx.filter = `blur(${blurRadius}px)`;
          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            const blurredImage = new File([blob], `blurred_${imageFile.name}`, { type: 'image/jpeg' });
            resolve(blurredImage);
          }, 'image/jpeg', 0.8); // Adjust the quality (0.0 - 1.0) as needed
        };
      };

      reader.readAsDataURL(imageFile);
    });
  };

  const blurAndResizeImage = async (imageFile, maxWidth, blurRadius) => {
    const resizedImage = await resizeImage(imageFile, maxWidth);
    const blurredImage = await blurImage(resizedImage, blurRadius);
    return blurredImage;
  };

  const handleUpload = async () => {
    if (image && blurredImage) {
      setClicked(true);
      // Upload resized image
      const resizedStorageRef = ref(storage, `profileImages/${pid}`);
      await uploadBytes(resizedStorageRef, image);
      const resizedImageUrl = await getDownloadURL(resizedStorageRef);

      // Upload blurred image
      const blurredStorageRef = ref(storage, `bluredProfileImages/${pid}`);
      await uploadBytes(blurredStorageRef, blurredImage);
      const blurredImageUrl = await getDownloadURL(blurredStorageRef);

      // Update data in Firebase
      const updatedData = {
        image: resizedImageUrl,
        blurredImage: blurredImageUrl
      };
      updateDataToFirebase(updatedData);

    } else {
      console.error('No image selected.');
    }
  };

  return (
    <Card h='300px' w='300px' justify='center'>
      <Box>
        <Input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
        />
        <Button
          isDisabled={!image || !blurredImage || clicked}
          onClick={handleUpload}
          colorScheme='pink'
          m='10px'
        >
          Upload Images
        </Button>
      </Box>
    </Card>
  );
};

export default ImageUpload;
