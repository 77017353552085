import React, { useState } from 'react';
import { Box, Button, Card, HStack, Heading, Input, Text } from '@chakra-ui/react';
import { auth } from '../../firebase/initFirebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [resetting, setResetting] = useState(false);
  const [resetSent, setResetSent] = useState(false);

  const handleResetPassword = async () => {
    setResetting(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setResetting(false);
    }
  };

  return (
    <Box p='10px' bgColor='pink.100' align='center'>
      <Card p='10px' w='320px'>
        <Box>
          {resetSent ? (
            <Box>
              <Heading my='20px' color='pink.500'>✅</Heading>
              <p>পাসওয়ার্ড পরিবর্তন করার জন্য {email} এই ইমেইলে একটি নির্দেশনা পাঠানো হয়েছে।
                দয়া করে আপনার ইনবক্স চেক করুন।
              </p>

              <HStack mt='100px' justify='center'>
                <Link to='/login'>
                  <Button colorScheme='pink' variant='outline'  mr='10px'>Login</Button>
                </Link>
                <Link to='/join'>
                  <Button colorScheme='pink' variant='outline'>Sign Up</Button>
                </Link>
              </HStack>
            </Box>
          ) : (
            <Box>
              <Heading my='20px' color='pink.500'>😲 Forgot?</Heading>
              <Text align='left' color='grey.200'>Email</Text>
              <Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <Button onClick={handleResetPassword} isLoading={resetting} w='300px' colorScheme='pink' mt='10px'>
                Reset
              </Button>

              <Text color='grey' mt='20px'>or go back to</Text>
              <Link to='/login'>
                <Button colorScheme='pink' variant='outline' mr='10px'>Login</Button>
              </Link>
              <Link to='/join'>
                <Button colorScheme='pink' variant='outline'>Sign Up</Button>
              </Link>
              {error && <Box mt={2} color="red">{error}</Box>}
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default ResetPassword;
