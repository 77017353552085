import React, { useState, useEffect } from 'react';
import { Card, Table, Tbody, Tr, Td, Input, Button, HStack, Text, Box, Select } from '@chakra-ui/react';
import { districts } from '../data/districts';

const Personal = ({ uData, updateDataToFirebase }) => {
  // Define state variables
  const [nameData, setNameData] = useState('');
  const [genderData, setGenderData] = useState('');
  const [birthYearData, setBirthYearData] = useState('');
  const [permanentLocationData, setPermanentLocationData] = useState('');
  const [presentLocationData, setPresentLocationData] = useState('');
  const [skinColorData, setSkinColorData] = useState('');
  const [heightData, setHeightData] = useState('');
  const [weightData, setWeightData] = useState('');
  const [maritalStatusData, setMaritalStatusData] = useState('');
  const [bloodGroupData, setbloodGroupData] = useState('');
  const [activeData, setActiveData] = useState('');

  const [noEdit, setNoEdit] = useState(true);

  // Update state when uData changes
  useEffect(() => {
    if (uData) {
      const { name = '', gender = '', birthYear = '', permanentLocation = '', 
              presentLocation = '', skinColor = '', maritalStatus = '',
              height = '', weight = '',  bloodGroup = '', active = '',
            } = uData;
      setNameData(name);
      setGenderData(gender);
      setBirthYearData(birthYear);
      setPermanentLocationData(permanentLocation);
      setPresentLocationData(presentLocation);
      setSkinColorData(skinColor);
      setHeightData(height);
      setWeightData(weight);
      setMaritalStatusData(maritalStatus);
      setbloodGroupData(bloodGroup);
      setActiveData(active);
    }
  }, [uData]);

  const handleSave = () => {
    const updatedData = {
      name: nameData,
      gender: genderData,
      birthYear: birthYearData,
      permanentLocation: permanentLocationData,
      presentLocation: presentLocationData,
      skinColor: skinColorData,
      maritalStatus: maritalStatusData,
      height: heightData,
      weight: weightData,
      bloodGroup: bloodGroupData,
      active: activeData,
    };
    updateDataToFirebase(updatedData);
    setNoEdit(true);
  };

  return (
    <Box>
      {uData && (
        <Card align='center' bgColor='pink.50' p='10px'>
          <Table size='sm'>
            <Tbody>
              {/* Personal info rows */}
              <Tr>
                <Td><Text>নাম</Text></Td>
                <Td>
                  <Input
                    value={nameData}
                    isDisabled={noEdit}
                    onChange={(e) => setNameData(e.target.value)}
                    _disabled={{ color: 'black', opacity: 1 }}
                  />
                </Td>
              </Tr>
              <Tr>
              <Td><Text>আইডির ধরন</Text></Td>
              <Td>
                <Select
                  value={genderData}
                  isDisabled={noEdit}
                  onChange={(e) => setGenderData(e.target.value)}
                  _disabled={{ color: 'black', opacity: 1 }}
                >
                  <option value="">সিলেক্ট করুন</option>
                  <option value="পাত্র">পাত্র</option>
                  <option value="পাত্রী">পাত্রী</option>
                  <option value="অন্যান্য">অন্যান্য</option>
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td><Text>জন্মসন</Text></Td>
              <Td>
                <Select
                  value={birthYearData}
                  isDisabled={noEdit}
                  onChange={(e) => setBirthYearData(e.target.value)}
                  _disabled={{ color: 'black', opacity: 1 }}
                >
                  <option value="">সিলেক্ট করুন</option>
                  {[...Array(66).keys()].map((year) => {
                    const selectYear = 1960 + year;
                    return <option value={selectYear} >{selectYear}</option>
                  })}
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td><Text>গায়ের রং</Text></Td>
              <Td>
                <Select
                  value={skinColorData}
                  isDisabled={noEdit}
                  onChange={(e) => setSkinColorData(e.target.value)}
                  _disabled={{ color: 'black', opacity: 1 }}
                >
                  <option value="">সিলেক্ট করুন</option>
                  <option value="ফর্সা">ফর্সা</option>
                  <option value="শ্যামলা">শ্যামলা</option>
                  <option value="কালো">কালো</option>
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td><Text>বৈবাহিক অবস্থা</Text></Td>
              <Td>
                <Select
                  value={maritalStatusData}
                  isDisabled={noEdit}
                  onChange={(e) => setMaritalStatusData(e.target.value)}
                  _disabled={{ color: 'black', opacity: 1 }}
                >
                  <option value="">সিলেক্ট করুন</option>
                  <option value="অবিবাহিত">অবিবাহিত</option>
                  <option value="বিবাহিত">বিবাহিত</option>
                  <option value="তালাকপ্রাপ্ত">তালাকপ্রাপ্ত</option>
                  <option value="বিধবা/বিপত্নীক">বিধবা/বিপত্নীক</option>
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td><Text>বর্তমান ঠিকানা</Text></Td>
              <Td>
                <Select
                  value={presentLocationData}
                  isDisabled={noEdit}
                  onChange={(e) => setPresentLocationData(e.target.value)}
                  _disabled={{ color: 'black', opacity: 1 }}
                >
                  <option value="">সিলেক্ট করুন</option>
                  {districts.map((district) => (
                    <option value={district} key={district}>{district}</option>
                  ))}
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td><Text>স্থায়ী ঠিকানা</Text></Td>
              <Td>
                <Select
                  value={permanentLocationData}
                  isDisabled={noEdit}
                  onChange={(e) => setPermanentLocationData(e.target.value)}
                  _disabled={{ color: 'black', opacity: 1 }}
                >
                  <option value="">সিলেক্ট করুন</option>
                  {districts.map((district) => (
                    <option value={district} key={district}>{district}</option>
                  ))}
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td><Text>উচ্চতা</Text></Td>
              <Td>
                <Input 
                  value={heightData} 
                  isDisabled={noEdit} 
                  onChange={(e)=> setHeightData(e.target.value)} 
                  _disabled={{color: 'black', opacity: 1,}} 
                />
              </Td>
            </Tr>
            <Tr>
              <Td><Text>ওজন</Text></Td>
              <Td>
                <Input 
                  value={weightData} 
                  isDisabled={noEdit} 
                  onChange={(e)=> setWeightData(e.target.value)} 
                  _disabled={{color: 'black', opacity: 1,}} 
                />
              </Td>
            </Tr>
            <Tr>
              <Td><Text>রক্তের গ্রুপ</Text></Td>
              <Td>
                <Select
                  value={bloodGroupData}
                  isDisabled={noEdit}
                  onChange={(e) => setbloodGroupData(e.target.value)}
                  _disabled={{ color: 'black', opacity: 1 }}
                >
                  <option value="">সিলেক্ট করুন</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td><Text>সার্চ রেজাল্টে দেখা যাবে?</Text></Td>
              <Td>
                <Select
                  value={activeData.toString()}
                  isDisabled={noEdit}
                  onChange={(e) => setActiveData(e.target.value === 'true')}
                  _disabled={{ color: 'black', opacity: 1 }}
                >
                  <option value={'true'}>হ্যাঁ</option>
                  <option value={'false'}>না</option>
                </Select>
              </Td>
            </Tr>
            </Tbody>
          </Table>
          <HStack pt='10px'>
            {!noEdit && (
              <Button onClick={() => setNoEdit(true)} colorScheme='pink'>
                Cancel
              </Button>
            )}
            {noEdit && (
              <Button onClick={() => setNoEdit(false)} colorScheme='pink'>
                Edit
              </Button>
            )}
            {!noEdit && (
              <Button onClick={handleSave} colorScheme='green'>
                Save
              </Button>
            )}
          </HStack>
        </Card>
      )}
    </Box>
  );
};

export default Personal;