import React from "react";
import { Box, Flex, Link, Icon, Card, Heading } from "@chakra-ui/react";
import {
  FaPhone,
  FaEnvelope,
  FaFacebook,
} from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom"
import { TbMessage } from "react-icons/tb";

const Contact = () => {
  return (
      <Box bgColor='pink.100' p='10px' align='center'>
        <Card p='10px' maxWidth='400px'>
        <Heading align='center' my='20px'>
          যোগাযোগ
        </Heading>
        <Flex align="left" mb={4}>
          <Icon as={TbMessage} fontSize="2xl" mr={4} />
          <RouterLink to="/chat/kobul" isExternal color="teal.500" fontSize="lg">
            {"Kobul Support"}
          </RouterLink>
        </Flex>
        <Flex align="left" mb={4}>
          <Icon as={FaPhone} fontSize="2xl" mr={4} />
          <Link href="tel:+8809696338801" color="teal.500" fontSize="lg">
            +880 9696 338801
          </Link>
        </Flex>
        <Flex align="left" mb={4}>
          <Icon as={FaEnvelope} fontSize="2xl" mr={4} />
          <Link href="mailto:contact@kobul.co" color="teal.500" fontSize="lg">
            contact@kobul.co
          </Link>
        </Flex>
        <Flex align="left" mb={4}>
          <Icon as={FaFacebook} fontSize="2xl" mr={4} />
          <Link href="https://facebook.com/groups/1144596960224982/" isExternal color="teal.500" fontSize="lg">
            {"Fb Group (বিয়ে- হালাল সম্পর্ক)"}
          </Link>
        </Flex>
        <Flex align="left" mb={4}>
          <Icon as={FaFacebook} fontSize="2xl" mr={4} />
          <Link href="https://facebook.com/kobulapp" isExternal color="teal.500" fontSize="lg">
            {"Facebook Page (Kobul App)"}
          </Link>
        </Flex>
        </Card>
      </Box>
  );
};

export default Contact;
