import React, { useEffect, useState } from 'react';
import { Card, Table, Tbody, Tr, Td, Button, HStack, Text, Heading, Textarea, Select } from '@chakra-ui/react';
import { districts } from '../data/districts';

const Expectation = ({ uData, updateDataToFirebase }) => {
  const [expectedAgeData, setExpectedAgeData] = useState('');
  const [expectedEducationData, setExpectedEducationData] = useState('')
  const [expectedPermanentLocationData, setExpectedPermanentLocationData] = useState('');
  const [expectedPresentLocationData, setExpectedPresentLocationData] = useState('');
  const [expectedSkinColorData, setExpectedSkinColorData] = useState('');
  const [expectedHeightData, setExpectedHeightData] = useState('');
  const [expectedWeightData, setExpectedWeightData] = useState('');
  const [expectedMaritalStatusData, setExpectedMaritalStatusData] = useState('');
  const [expectedOthersData, setExpectedOthersData] = useState('');

  const [noEdit, setNoEdit] = useState(true);

  useEffect(() => {
    if (uData) {
      const {
        expectedAge = '', expectedPermanentLocation = '', expectedEducation = '',
        expectedPresentLocation = '', expectedSkinColor = '', expectedMaritalStatus = '',
        expectedHeight = '', expectedWeight = '', expectedOthers = '',
      } = uData;
      setExpectedAgeData(expectedAge);
      setExpectedEducationData(expectedEducation)
      setExpectedPermanentLocationData(expectedPermanentLocation);
      setExpectedPresentLocationData(expectedPresentLocation);
      setExpectedSkinColorData(expectedSkinColor);
      setExpectedHeightData(expectedHeight);
      setExpectedWeightData(expectedWeight);
      setExpectedMaritalStatusData(expectedMaritalStatus);
      setExpectedOthersData(expectedOthers);
    }
  }, [uData]);

  const handleSave = () => {
    const updatedData = {
      expectedAge: expectedAgeData,
      expectedEducation: expectedEducationData,
      expectedSkinColor: expectedSkinColorData,
      expectedHeight: expectedHeightData,
      expectedWeight: expectedWeightData,
      expectedOthers: expectedOthersData,
      expectedPresentLocation: expectedPresentLocationData,
      expectedPermanentLocation: expectedPermanentLocationData,
      expectedMaritalStatus: expectedMaritalStatusData,
    };
    updateDataToFirebase(updatedData);
    setNoEdit(true);
  };

  return (
    <Card align='center' bgColor='pink.50' p='10px'>
      <Heading>প্রত্যাশা</Heading>
      <Table size='sm'>
        <Tbody>
          <Tr>
            <Td><Text>বয়স</Text></Td>
            <Td>
              <Select
                value={expectedAgeData}
                isDisabled={noEdit}
                onChange={(e) => setExpectedAgeData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="যেকোনো">যেকোনো</option>
                <option value="১৮ - ২৫">১৮ - ২৫</option>
                <option value="২৬ - ৩০">২৬ - ৩০</option>
                <option value="৩১ - ৪০">৩১ - ৪০</option>
                <option value="৪১ - ৫৯">৪১ - ৫৯</option>
                <option value="৬০ এর অধিক">৬০ এর অধিক</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>গায়ের রং</Text></Td>
            <Td>
              <Select
                value={expectedSkinColorData}
                isDisabled={noEdit}
                onChange={(e) => setExpectedSkinColorData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="যেকোনো">যেকোনো</option>
                <option value="ফর্সা">ফর্সা</option>
                <option value="শ্যামলা">শ্যামলা</option>
                <option value="কালো">কালো</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>শিক্ষা</Text></Td>
            <Td>
              <Select
                value={expectedEducationData}
                isDisabled={noEdit}
                onChange={(e) => setExpectedEducationData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option>সিলেক্ট করুন</option>
                <option value="যেকোনো">যেকোনো</option>
                <option value="মাস্টার্স/সমমান">মাস্টার্স/সমমান</option>
                <option value="অনার্স/সমমান">অনার্স/সমমান</option>
                <option value="ডিগ্রি/সমমান">ডিগ্রি/সমমান</option>
                <option value="এইচএসসি">এইচএসসি</option>
                <option value="এসএসসি">এসএসসি</option>
                <option value="প্রাথমিক শিক্ষা">প্রাথমিক শিক্ষা</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>বৈবাহিক অবস্থা</Text></Td>
            <Td>
              <Select
                value={expectedMaritalStatusData}
                isDisabled={noEdit}
                onChange={(e) => setExpectedMaritalStatusData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option>সিলেক্ট করুন</option>
                <option value="যেকোনো">যেকোনো</option>
                <option value="অবিবাহিত">অবিবাহিত</option>
                <option value="বিবাহিত">বিবাহিত</option>
                <option value="তালাকপ্রাপ্ত">তালাকপ্রাপ্ত</option>
                <option value="বিধবা/বিপত্নীক">বিধবা/বিপত্নীক</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>বর্তমান ঠিকানা</Text></Td>
            <Td>
              <Select
                value={expectedPresentLocationData}
                isDisabled={noEdit}
                onChange={(e) => setExpectedPresentLocationData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option>সিলেক্ট করুন</option>
                <option value="যেকোনো">যেকোনো</option>
                {districts.map((district) => (
                  <option value={district} key={district}>{district}</option>
                ))}
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>স্থায়ী ঠিকানা</Text></Td>
            <Td>
              <Select
                value={expectedPermanentLocationData}
                isDisabled={noEdit}
                onChange={(e) => setExpectedPermanentLocationData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option>সিলেক্ট করুন</option>
                <option value="যেকোনো">যেকোনো</option>
                {districts.map((district) => (
                  <option value={district} key={district}>{district}</option>
                ))}
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>উচ্চতা</Text></Td>
            <Td>
              <Textarea
                value={expectedHeightData} 
                isDisabled={noEdit} 
                onChange={(e)=> setExpectedHeightData(e.target.value)} 
                _disabled={{color: 'black', opacity: 1,}} 
              />
            </Td>
          </Tr>
          <Tr>
            <Td><Text>ওজন</Text></Td>
            <Td>
              <Select
                value={expectedWeightData}
                isDisabled={noEdit}
                onChange={(e) => setExpectedWeightData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="যেকোনো">যেকোনো</option>
                <option value="৪০ এর কম">৪০ এর কম</option>
                <option value="৪১-৪৫">৪১-৪৫</option>
                <option value="৪৬-৫০">৪৬-৫০</option>
                <option value="৫১-৫৫">৫১-৫৫</option>
                <option value="৫৬-৬০">৫৬-৬০</option>
                <option value="৬১-৬৫">৬১-৬৫</option>
                <option value="৬৬-৭০">৬৬-৭০</option>
                <option value="৭১-৮০">৭১-৮০</option>
                <option value="৮১-৯০">৮১-৯০</option>
                <option value="৯০ এর অধিক">৯০ এর অধিক</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>অন্যান্য</Text></Td>
            <Td>
              <Textarea
                value={expectedOthersData} 
                isDisabled={noEdit} 
                onChange={(e)=> setExpectedOthersData(e.target.value)} 
                _disabled={{color: 'black', opacity: 1,}} 
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <HStack pt='10px'>
        {!noEdit && (
          <Button onClick={() => setNoEdit(true)} colorScheme='pink'>
            Cancel
          </Button>
        )}
        {noEdit && (
          <Button onClick={() => setNoEdit(false)} colorScheme='pink'>
            Edit
          </Button>
        )}
        {!noEdit && (
          <Button onClick={handleSave} colorScheme='green'>
            Save
          </Button>
        )}
      </HStack>
    </Card>
  );
};

export default Expectation;
