import React from 'react'
import { Helmet } from 'react-helmet'

const Privacy = () => {
  return (
    <div>
      <p>
      <h1>Kobul Privacy Policy</h1>

<p><strong>Effective Date: 14-February-2024</strong></p>

<p>At Kobul, your privacy is a top priority. This Privacy Policy explains how we collect, use, share, and protect your personal information on our Bangladeshi matrimony website.</p>

<h2>Information We Collect</h2>

<ul>
  <li><strong>Registration Information:</strong> Name, date of birth, gender, contact details (email, phone), location, religious background, family details, education, profession.</li>
  <li><strong>Profile Information:</strong> Photos, personal descriptions, partner preferences.</li>
  <li><strong>Usage Data:</strong> Information about how you use Kobul, including pages visited, features used, matches viewed, and communications sent.</li>
  <li><strong>Device Data:</strong> IP address, browser type, device information when you access Kobul.</li>
  <li><strong>Payment Information:</strong> Billing details, transaction history, if you choose to purchase premium services.</li>
</ul>

<h2>How We Use Your Information</h2>

<ul>
  <li><strong>Provide Matrimonial Services:</strong> Create your profile, suggest compatible matches, facilitate communication with potential partners.</li>
  <li><strong>Improve Our Platform:</strong> Analyze usage data to improve functionality and user experience.</li>
  <li><strong>Customer Support:</strong> Respond to your inquiries, troubleshoot issues, provide personalized assistance.</li>
  <li><strong>Security and Fraud Prevention:</strong> Detect and protect against fraudulent activity or unauthorized access.</li>
  <li><strong>Marketing and Promotions (with your consent):</strong> Send updates, offers about Kobul or related services.</li>
</ul>

<h2>Information Sharing</h2>

<ul>
  <li><strong>Potential Matches:</strong> Limited profile information is shared with compatible matches based on your preferences.</li>
  <li><strong>Service Providers:</strong> We may share information with third-party vendors who support website operations, payment processing, or marketing. They are bound by confidentiality agreements.</li>
  <li><strong>Legal Compliance:</strong> If required by law, we may disclose information to comply with legal processes or government requests.</li>
  <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale, your information may be transferred to another organization.</li> 
</ul>

<h2>Your Rights and Choices</h2>

<ul>
  <li><strong>Access and Update:</strong> You can review and edit your profile information.</li>
  <li><strong>Communications:</strong> Manage your email preferences and opt-out of promotional messages.</li>
  <li><strong>Data Deletion:</strong> You may request the removal of your personal information, subject to certain limitations.</li>
</ul>

<h2>Security</h2>

<p>Safeguarding your information is our responsibility. We use technical, administrative, and physical measures to protect it from unauthorized access, disclosure, or misuse.</p>

<h2>Children's Privacy</h2>

<p>Kobul is for adults only (18+). We do not knowingly collect information from individuals under 18.</p>

<h2>Changes to This Policy</h2>

<p>We may periodically update this Privacy Policy. We'll notify you of significant changes by posting the updated policy with a revised effective date.</p>

<h2>Contact Us</h2> 

<p>If you have questions about our privacy practices, please contact us at: contact@kobul.co</p>

      </p>
      <Helmet>
        <title>Kobul - Privacy Policy</title>
        <meta name="description" content="At Kobul, your privacy is a top priority. This Privacy Policy explains how we collect, use, share, and protect your personal information on our Bangladeshi matrimony website." />
      </Helmet>
    </div>
  )
}

export default Privacy