import React, { useContext } from 'react'
import { Box, SimpleGrid, useToast, Text } from '@chakra-ui/react'
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/initFirebase';

import Expectation from './Expectation';
import Family from './Family';
import Personal from './Personal';
import Professional from './Professional';
import ProfileImage from './ProfileImage';
import Contact from './Contact';
import { AuthContext } from '../../context/AuthContext';

const ManageProfile = () => {

  const { uData, uid, setReloadProfiles } = useContext(AuthContext);

  const toast = useToast()

  const profile = {id:uid, ...uData}

  const updateDataToFirebase = async (updatedData) => {
    try {
      const docRef = doc(db, 'profile', profile.id);
      await updateDoc(docRef, updatedData );
      setReloadProfiles(Date.now());
      toast({
        title: 'Saved',
        description: "Your Data Saved Successfully",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Failed',
        description: "Try Again later", error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  };

  return (
    <Box>
      <SimpleGrid columns={{ md: 2}} spacing='10px' bgColor='pink.100' py='10px'>
        <ProfileImage uData={profile} updateDataToFirebase={updateDataToFirebase} />
        <Personal uData={profile} updateDataToFirebase={updateDataToFirebase} />
        <Professional uData={profile} updateDataToFirebase={updateDataToFirebase} />
        <Family uData={profile} updateDataToFirebase={updateDataToFirebase} />
        <Expectation uData={profile} updateDataToFirebase={updateDataToFirebase} />
        <Contact uData={profile} updateDataToFirebase={updateDataToFirebase} />
      </SimpleGrid>
      <Box align='center' p='10px' bgColor='pink.50'>
        <Text>
          ** ভুয়া, মিথ্যা বা অসম্পূর্ন বায়োডাটা সার্চ রেজাল্ট থেকে সরানো হলে সঠিক ও সম্পূর্ন তথ্য দিয়ে পূনরায় বায়োডাটা পাবলিশ করুন!
        </Text>
      </Box>
    </Box>
  )
}

export default ManageProfile