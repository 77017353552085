import React from 'react';
import { Box, Image, Heading, Text, HStack, Button, IconButton } from '@chakra-ui/react';
import { FaTrash } from "react-icons/fa";
import ImageUpload from './ImageUpload';
import { MdVerified } from 'react-icons/md';

const ProfileImage = ({ uData, updateDataToFirebase }) => {

  const currentYear = new Date().getFullYear();

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(`kobul.co/id/${uData.id}`);
  };

  const handleImageDelete = () => {
    const updatedData = {
      image: ""
    };
    updateDataToFirebase(updatedData);
  };

  const handlePublishBiodata = () => {
    const updatedData = {
      active: true,
    };
    updateDataToFirebase(updatedData);
  };

  return (
    <Box align='center' p='10px' bgColor='pink.50' borderRadius='5px'>
      <Box align='center' w='302px'>
        {(uData.image && uData.image.length > 5 &&
          <Box align='left' mb='-40px'>
            <IconButton
              icon={<FaTrash />}
              onClick={handleImageDelete}
            />
          </Box>
        )}
        {(!uData.image || uData.image.length < 5) && // Added condition to check if image data does not exist or is too short
          <ImageUpload pid={uData.id} updateDataToFirebase={updateDataToFirebase} />
        }
        {(uData.image && uData.image.length > 5) && // Added condition to check if image data exists and is long enough
          <Image src={uData.image} h='300px' w='300px'
            borderRadius='10px' alt='Profile Photo' fit='cover'
          />
        }
      </Box>

      <Box w='300px' align='left'>
        <HStack>
          <Heading fontSize='20px'>{uData.name}</Heading>
          {uData.verified && <MdVerified color='#0084FF' size='17px' />}
        </HStack>
        <Text>বয়সঃ {currentYear - uData.birthYear}</Text>
        <HStack>
          <Text><strong>kobul.co/id/{uData.id}</strong></Text>
          <Button onClick={copyTextToClipboard} colorScheme='facebook' size='xs'>🔗</Button>
        </HStack>
        
      </Box>
      {!uData.active &&
        <Box align='left' p='10px' mt='5px' bgColor='pink.100' borderRadius='5px'>
          <Text>আপনার বায়োডাটা সার্চ রেজাল্টে দেখা যাচ্ছে না। দেখাতে চাইলে নিচের বাটনে ক্লিক করে বায়োডাটা পাবলিশ করুন।</Text>
          <Button colorScheme='green' size='sm' mt='5px' onClick={handlePublishBiodata}>👉 পাবলিশ বায়োডাটা</Button>
        </Box>
      }
    </Box>
  )
}

export default ProfileImage;
