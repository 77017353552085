import { Box, Button, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const LoginScreen = () => {
  return (
    <Box px='20px' py='100px' bgColor='pink.50' minHeight='350px' align='center'>
      <Text p='10px'>দয়া করে লগইন করুন!!</Text>
      <Link to='/login'>
        <Button m='10px' colorScheme='pink'>
        Login
        </Button>
      </Link>
    </Box>
  )
}

export default LoginScreen