import { Box, Card, Heading } from '@chakra-ui/react'
import React from 'react'

const LoadingScreen = () => {
  return (
    <Box p='10px'>
      <Card minH='85vh' p='10px'>
        <Heading mt='40vh' color='pink.500'>Loading...</Heading> 
      </Card>
    </Box>
  )
}

export default LoadingScreen