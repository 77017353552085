import React from 'react';
import Home from './Home'

const MobileApp = () => {
  
  return (
    <Home/>
  );
};

export default MobileApp;
