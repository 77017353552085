import React, { useContext, useEffect, useState } from 'react';
import { Box, Table, Tbody, Tr, Td, Text, Button, Modal, ModalOverlay, ModalContent, Image, HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { db } from '../../firebase/initFirebase';
import { collection, query, where, getDocs, getDoc, doc, orderBy } from 'firebase/firestore';
import { AuthContext } from '../../context/AuthContext';

const ProposeSent = () => {
  const [sentRequests, setSentRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pData, setPData] = useState(null);
  const [photoModal, setPhotoModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);

  useEffect(() => {
    const fetchSentRequests = async () => {
      const sentRequestsQuery = query(
        collection(db, 'request'),
        where('senderUid', '==', currentUser.uid),
        orderBy('timestamp', 'desc')
      );
      const sentRequestsSnapshot = await getDocs(sentRequestsQuery);
      const sentRequestsData = [];
      sentRequestsSnapshot.forEach((doc) => {
        sentRequestsData.push({ id: doc.id, ...doc.data() });
      });
      setSentRequests(sentRequestsData);
      setLoading(false);
    };
    if (currentUser) {
      fetchSentRequests();
    }
  }, [currentUser]);

  const openPhotoModal = (pid) => {
      setPData(null);
      const fetchDoc = async () => {
        try {
            const docRef = doc(db, 'profile', pid);
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
              setPData(docSnap.data());
            } 
          } catch (error) {
          console.error('Error fetching document:', error);
        }
      };
      
    fetchDoc();
    setIsModalOpen(true);
    setPhotoModal(true);
  };

  const openContactModal = (pid) => {
    setPData(null);
    const fetchDoc = async () => {
      try {
          const docRef = doc(db, 'profile', pid);
          const docSnap = await getDoc(docRef);
  
          if (docSnap.exists()) {
            setPData(docSnap.data());
          } 
        } catch (error) {
        console.error('Error fetching document:', error);
      }
    };
    
  fetchDoc();
  setIsModalOpen(true);
  setContactModal(true);
};

  const closeModal = () => {
    setIsModalOpen(false);
    setContactModal(false);
    setPhotoModal(false);
  };

  return (
    <Box>
      {loading ? ( 
        <Text>Loading...</Text>
      ) : (
        <Table mt='10px' border='2px' borderColor='pink.200' size='sm'>
          <Tbody>
            {sentRequests.map(request => (
              <Tr key={request.id}>
                <Td width='50%' border='1px' borderColor='pink.200'>
                  <Text>
                  <Link to={`/id/${request.receiverId}`} target="_blank" rel="noopener noreferrer">
                    <Text as='span' color='pink.500'><strong>{request.receiverId}</strong></Text>{" "}
                  </Link>
                    আইডিকে আপনি 
                    {request.type === 'contact' ? (
                      <strong>🤝সাক্ষাৎ করার </strong>
                      ) :  <strong>📷ছবি দেখার </strong>
                    } 
                    প্রস্তাব দিয়েছিলেন
                  </Text>
                </Td>
                <Td w='50%' border='1px' borderColor='pink.200'>
                  {request.status === 'accepted' ? (
                    <>
                      <Text>অভিনন্দন! তিনি আপনার প্রস্তাবে সম্মতি দিয়েছেন।</Text>
                      {request.type === 'contact' ? (
                          <Button size='xs' colorScheme='pink' onClick={() => openContactModal(request.receiverId)}>সাক্ষাৎ এর তথ্য দেখুন</Button>
                        ) : <Button size='xs' colorScheme='pink' onClick={() => openPhotoModal(request.receiverId)}>ছবি দেখুন</Button>
                      }
                    </>
                  ) : request.status === 'rejected' ? (
                    <Text>দুঃখিত তিনি আপনার প্রস্তাবে সম্মতি দেন নাই।</Text>
                  ) : (
                    <Text>আপনার প্রস্তাবটি অপেক্ষমান রয়েছে, উত্তরের জন্য অপেক্ষা করুন।</Text>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      
      <Modal isOpen={isModalOpen}>
        <ModalOverlay />
        <ModalContent>
          { pData && photoModal &&
            <Box align='center'>
              <HStack justifyContent='space-between'>
              <Text p='5px' fontSize='20px'>{pData.name}</Text>
              <Button colorScheme="pink" onClick={closeModal} borderRadius='0px'>
                Close
              </Button>
              </HStack>
              <Image src={pData.image} alt="Photo" w='300px' h='300px' fit='cover' borderRadius='5px' />
            </Box>
          }
          { pData && contactModal &&
            <Box align='center' >
              <HStack justifyContent='space-between'>
              <Text p='5px' fontSize='20px'>{pData.name}</Text>
              <Button colorScheme="pink" onClick={closeModal} borderRadius='0px'>
                Close
              </Button>
              </HStack>
              <Image src={pData.image} alt="Photo" w='150px' h='150px' fit='cover' />
              <Text p='5px' fontSize='20px'>যোগাযোগের তথ্য</Text>
              <Text>যোগাযোগকারীঃ {pData.contactPerson || '' }</Text>
              <Text>মোবাইলঃ {pData.contactMobile || '' }</Text>
            </Box>
          }
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProposeSent;
