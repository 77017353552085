import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, useParams } from 'react-router-dom';
import { collection, doc, onSnapshot, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { db } from '../../firebase/initFirebase';
import { Box, Button, Card, HStack, Image, Input, Select, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import LoginScreen from '../utility/LoginScreen';
import { MdVerified } from 'react-icons/md';

const Chat = () => {
  const [inputData, setInputData] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [dbSetting, setDbSetting] = useState(false);

  const { uid, currentUser, uData, idsUnderMe = [] } = useContext(AuthContext); // Initialize idsUnderMe with an empty array
  const { pid } = useParams();
  const [noId, setNoId] = useState(false);
  const [pData, setPData] = useState(null);
  const [myData, setMyData] = useState(null);
  const [myId, setMyId] = useState(uid); // Initialize with uid

  const [noConversationId, setNoConversationId] = useState(false);
  const [convId, setConvId] = useState();
  const [convRefData, setConvRefData] = useState();

  const [noChat, setNoChat] = useState(false);
  const [chatData, setChatData] = useState();
  
  const [reloadConvId, setReloadConvId] = useState(false);
  const chatBottomRef = useRef(null);

  // fetch Partner Profile Data
  useEffect(() => {
    const fetchPartner = async () => {
      try {
        const partnerDocSnap = await getDoc(doc(db, 'profile', pid));
        if (partnerDocSnap.exists()) {
          setPData(partnerDocSnap.data());
          setNoId(false);
        } else {
          setNoId(true);
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };
    fetchPartner();
  }, [pid]);

  // Fetch Conversation ID
  useEffect(() => {
    if (myId) {
      const fetchConversationId = async () => {
        try {
          const convQuery = query(
            collection(db, 'convRef'),
            where('p1Id', 'in', [myId, pid]),
            where('p2Id', 'in', [pid, myId]),
          );
          const convSnapshot = await getDocs(convQuery);
          const data = [];
          convSnapshot.docs.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });
          if (data.length === 0) {
            setNoConversationId(true);
            setChatData(null);
          } else {
            setConvId(data[0].convId);
            setConvRefData(data[0]);
            setNoConversationId(false);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchConversationId();
    }
  }, [reloadConvId, currentUser, pid, myId]);

  // fetch myData
  useEffect(() => {
    const fetchMyData = async () => {
      if (myId) {
        try {
          const myDocSnap = await getDoc(doc(db, 'profile', myId));
          if (myDocSnap.exists()) {
            setMyData(myDocSnap.data());
          }
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      }
    };
    fetchMyData();
  }, [myId]);

  // Fetch Chat Data
  useEffect(() => {
    if (convId) {
      const docRef = doc(db, 'conversation', convId);
      const fetchMessages = async () => {
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data) {
              const messagesArray = Object.keys(data)
                .map(key => ({ id: key, ...data[key] }))
                .sort((a, b) => a.id.localeCompare(b.id));
              setChatData(messagesArray);
              setNoChat(false);
            } else {
              setChatData(null);
            }
          } else {
            setNoChat(true);
          }
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      };
  
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data) {
            const messagesArray = Object.keys(data)
              .map(key => ({ id: key, ...data[key] }))
              .sort((a, b) => a.id.localeCompare(b.id));
            setChatData(messagesArray);
            setNoChat(false);
          }
        } else {
          setNoChat(true);
          setChatData(null);
        }
      });
      fetchMessages();
      return () => unsubscribe();
    }
  }, [convId]);
  
  // scroll chat to bottom
  useEffect(() => {
    const scrollToBottom = () => {
      chatBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    scrollToBottom();
  }, [chatData]);

  const handleConvDbSet = async () => {
    setDbSetting(true);
    const bothId = `${myId}-${pid}`;
    const convRefData = {
      p1Id: myId,
      p1Name: uData.name || '',
      p1Uid: currentUser.uid,
      p1Avatar: uData.blurredImage || '',
      p2Id: pid,
      p2Name: pData.name || '',
      p2Uid: pData.ownerUid,
      p2Avatar: pData.blurredImage || '',
      convId: bothId,
      lastMessage: '👋',
      lastMessageSenderUid: currentUser.uid,
      lastMessageTime: Number(`${new Date().toISOString().slice(0, 23).replace(/[-:.T]/g, '')}`)
    };
    const conversationData = {
      [Number(`${new Date().toISOString().slice(0, 23).replace(/[-:.T]/g, '')}`)]: {
        message: '👋',
        senderId: uid,
        timestamp: new Date(),
        senderUid: currentUser.uid
      }
    };
    try {
      await setDoc(doc(collection(db, 'convRef'), bothId), convRefData);
      try {
        await setDoc(doc(collection(db, 'conversation'), bothId), conversationData);
        setReloadConvId(new Date());
      } catch (error) {
        console.error('Error inserting conversation: ', error);
      }
    } catch (error) {
      console.error('Error inserting conRef: ', error);
    }
  };

  const handleSend = async () => {
    if (inputData.length < 1) {
      return;
    }
    setIsSending(true);
    try {
      const messageTimestamp = Number(`${new Date().toISOString().slice(0, 23).replace(/[-:.T]/g, '')}`);
      const newMessage = {
        id: messageTimestamp,
        message: inputData,
        senderUid: currentUser.uid,
        senderId: uid,
        timestamp: new Date(),
      };

      // Add the new message to the chat data
      const updatedChatData = [...(chatData || []), newMessage];
  
      // Keep only the latest 30 messages
      const latestChatData = updatedChatData.slice(-30);
  
      // Convert messages array back to object
      const updatedMessages = {};
      latestChatData.forEach(message => {
        updatedMessages[message.id] = {
          message: message.message,
          senderUid: message.senderUid,
          senderId: message.senderId,
          timestamp: message.timestamp,
        };
      });
  
      const lastMessageUpdateData = {
        lastMessage: inputData,
        lastMessageSenderUid: currentUser.uid,
        [convRefData.p1Uid === currentUser.uid ? 'p1Name' : 'p2Name']: myData.name || '',
        [convRefData.p1Uid === currentUser.uid ? 'p1Avatar' : 'p2Avatar']: myData.blurredImage || '',
        lastMessageTime: messageTimestamp,
      };
  
      const docRef = doc(db, 'conversation', convId);
  
      await setDoc(docRef, updatedMessages);
      await updateDoc(doc(db, 'convRef', convId), lastMessageUpdateData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSending(false);
      setInputData('');
    }
  };

  return (
    <Box bgColor="pink.100" px={{ lg: '100px' }} pb='10px'>
      {noId && (
        <Box p='10px'>
          <Card p='20px' align='center'>
            <Text p='10px'>এই প্রফাইল টি পাওয়া যায় নি, অনুগ্রহ পূর্বক আইডি পূনরায় চেক করুন</Text>
            <Link to='/chat'>
              <Button m='10px'>
              ← Go Back
              </Button>
            </Link>
          </Card>
        </Box>
      )}
      {pData && uid && (
        <Box bgColor="white" p="10px" mb='3px' position="sticky" top="59px" zIndex="1">
          <HStack justify="space-between">
            <HStack>
              <Link to='/chat'>
                <Button colorScheme='pink' variant='outline' size='sm'>
                ←
                </Button>
              </Link>
              <Image src={pData.blurredImage} w="40px" h="40px" fit='cover' borderRadius="20px" />
              <p><strong>{pData.name}</strong></p>
              {pData.verified && <MdVerified color='#0084FF' size='17px' />}
            </HStack>
            <HStack>
              {idsUnderMe.length > 0 ? (
                  <Select 
                    onChange={(e) => setMyId(e.target.value)}
                    value={myId || ''}
                  >
                    <option value={uid}>{uData?.name}</option>
                    {idsUnderMe.map((id) => (
                      <option value={id} key={id}>{id}</option>
                    ))}
                  </Select>
                ) : (
                 <Text>{uData?.name}</Text>
                )
              }
              <Image src={myData?.blurredImage} w="40px" h="40px" fit='cover' borderRadius="20px" />
            </HStack>
          </HStack>
        </Box>
      )}
      {!currentUser && 
        <LoginScreen />
      }
      {!uid && currentUser &&
        <Box px='20px' py='100px' bgColor='pink.50' minHeight='350px' align='center'>
          <Text p='10px'>আপনার কোনো প্রফাইল নেই। ম্যাসেজ দিতে চাইলে দয়া করে নিজের প্রোফাইল তৈরি করুন</Text>
          <Link to='/newprofile'>
            <Button m='10px' colorScheme='pink'>
            প্রোফাইল তৈরি করুন
            </Button>
          </Link>
        </Box>
      }
      {noConversationId && uid && (
        <Box>
          <Card p='20px' align='center'>
          { pData.ownerUid !== currentUser.uid ?
          <>
            <Text p='10px' color='gray'>ইতিপূর্বে আপনি {pData.name} এর সাথে কোনো ম্যাসেজ করেন নি</Text>
              <Button m='10px' colorScheme='pink' isLoading={dbSetting} onClick={handleConvDbSet}>
                ম্যাসেজ শুরু করুন 👋
              </Button>
          </>  : <Text>আপনি নিজের আইডিতে ম্যাসেজ দিতে পারবেন না।</Text>
          }
          </Card>
        </Box>
      )}
      {noChat && convId && (
        <Box>
          <Card p='20px' align='center'>
            <Text p='10px' color='gray'>ম্যাসেজ শুরু করুন</Text>
          </Card>
        </Box>
      )}
      {chatData && (
      <>
        <Box
          px="5px"
          pt="5px"
          position="relative"
          overflowY="auto"
          bgColor="pink.50"
          style={{ maxHeight: 'calc(100vh - 200px)' }} // Limit chat window height
        >
          {chatData.map((messages) => (
            <Box key={messages.id} px="10px" py='5px' borderRadius="25px"  mb="5px"
              ml={messages.senderUid === currentUser.uid ? "100px" : "0px"}
              mr={messages.senderUid === currentUser.uid ? "0px" : "100px"}
              bgColor={messages.senderUid === currentUser.uid ? "pink.500" : "#D4D4D4"}
              textAlign={messages.senderUid === currentUser.uid ? "right" : "left"}
              textColor={messages.senderUid === currentUser.uid ? "white" : "black"}
            >
              <Text>
                {messages.message}
              </Text>
            </Box>
          ))}
          <div ref={chatBottomRef} />
        </Box>
        
        <Box bgColor="white" pt="10px" pb='5px' px='10px' position="sticky" bottom="0">
            <HStack justify="left">
              <Input value={inputData} onChange={(e) => setInputData(e.target.value)} placeholder='type here...' />
                <Button colorScheme="pink" isLoading={isSending} onClick={handleSend}>
                  Send
                </Button>
            </HStack> 
          <Text mt='3px' fontSize='xs' align='center' color='grey'>গোপনীয়তার স্বার্থে শুধুমাত্র শেষ ৩০ টি ম্যাসেজ হিস্টোরিতে সংরক্ষন করা হয়</Text>
        </Box> 
      </>
      )}
      <Helmet>
        <title>{`Kobul Chat - Chat privately with ${pid}`}</title>
        <meta name="description" content="You can chat with your partner private, secured & free inside kobul app" />
      </Helmet>
    </Box>
  );
};

export default Chat;
