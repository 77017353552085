import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Box, Button, Text, Card } from '@chakra-ui/react';

const IdSearch = () => {
  const [inputPid, setInputPid] = useState('');

  const handleUidInput = (e) => {
    setInputPid(e.target.value);
  };

  return (
      <Box py='10px' px={{base:'10px', lg:'100px'}} bgColor='pink.100'>
        <Card bgColor='pink.50' p='10px'>
        <Box >
          <label>Enter a Kobul ID Here</label>
          <form>
            <Input
              type="text"
              value={inputPid}
              onChange={handleUidInput}
              placeholder="Enter ID"
              mb={4}
            />
            <Link to={`/id/${inputPid}`}>
              <Text>Click below button to visit kobul.co/id/{inputPid}</Text>
              <Button colorScheme="pink">See Profile</Button>
            </Link>
          </form>
        </Box>
        </Card>
      </Box>
      
  );
};

export default IdSearch;
