import { useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase/initFirebase';

const PrimaryProposeButton = ({ senderUid, senderId, receiverUid, receiverId }) => {
  const [isSending, setIsSending] = useState(false);

  const toast = useToast()

  const handleSendRequest = async () => {
    setIsSending(true);

    try {
      const requestData = {
        type: 'photo',
        senderUid : senderUid,
        senderId : senderId,
        receiverUid : receiverUid,
        receiverId : receiverId,
        timestamp: new Date(),
        status: 'new',
      };

      // Write data to Firestore
      await addDoc(collection(db, 'request'), requestData);
      toast({
        title: 'প্রস্তাব পাঠানো হয়েছে',
        description: "অপরপক্ষের নিকট হতে সাড়া আসতে অপেক্ষা করুন, ধন্যবাদ",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })

    } catch (error) {
      toast({
        title: 'কিছু একটা সমস্যা হয়েছে',
        description: "দুঃখিত, এই মুহুর্তে প্রস্তাবটি পাঠানো সম্ভব হয় নি" + error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })

    } finally {
      setIsSending(false);
    }
  };

  return (
    <Button 
      onClick={handleSendRequest} 
      isLoading={isSending} 
      colorScheme='pink' size='sm'
      >
      ছবি দেখার প্রস্তাব
    </Button>
  );
};

export default PrimaryProposeButton;
