import React, { useContext } from 'react';

import { Box, } from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';
import Landing from './Landing';
import { Helmet } from 'react-helmet';

import LoadingScreen from '../utility/LoadingScreen';
import BiodataCard from './BiodataCard';

const Home = () => {
  const { currentUser, userLoading } = useContext(AuthContext);

  return (
    <Box bgColor='pink.100'>
      { userLoading ? 
        <LoadingScreen/> 
      : 
      <>
        {currentUser ?
          <BiodataCard />
        :
          <Landing />
        }
      </>
      }
      <Helmet>
        <title>Kobul - Matrimony matchmaking app</title>
        <meta name="description" content="Create profile(wedding biodata), search profile, chat with each other, send propose, get married, everything inside kobul app" />
      </Helmet>
    </Box>
  );
};

export default Home;
