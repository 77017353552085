import React, { useContext, useState } from 'react';
import { Box, Card, ButtonGroup, Button, Text } from '@chakra-ui/react';
import ProposeSent from './ProposeSent';
import ProposeReceived from './ProposeReceived';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Propose = () => {
  const { currentUser } = useContext(AuthContext);

  const [recActive, setRecActive] = useState(true);
  const [sentActive, setSentActive] = useState(false);

  const handleRecClick = () => {
    setRecActive(true);
    setSentActive(false);
  };

  const handleSentClick = () => {
    setSentActive(true);
    setRecActive(false);
  };

  return (
    <Box py='10px' px={{ base: '10px', lg: '100px' }} bgColor='pink.100'>
      <Card p='10px' bgColor='pink.50'>
        <ButtonGroup variant='outline'  spacing='1px' colorScheme='pink' alignSelf='center' size='sm'>
          <Button onClick={handleRecClick} isActive={recActive} borderRadius='1px' minW='100px'>⬇️ প্রস্তাব এসেছে</Button>
          <Button onClick={handleSentClick} isActive={sentActive} borderRadius='1px' minW='100px'>পাঠানো হয়েছে ⬆️</Button>
        </ButtonGroup>
        {currentUser && 
          <>
            {recActive && <ProposeReceived />}
            {sentActive && <ProposeSent />}
          </>
        }
      </Card>
      {!currentUser && (
        <Box px='20px' py='100px' bgColor='pink.50' minHeight='350px' align='center'>
            <Text p='10px'>দয়া করে লগইন করুন!!</Text>
            <Link to='/login'>
              <Button m='10px' colorScheme='pink'>
              Login
              </Button>
            </Link>
        </Box>
      )}
      <Helmet>
        <title>Kobul Propose - See who proposed you</title>
        <meta name="description" content="You can see who proposed you and their photo inside kobul app, you can also accept and reject proposal" />
      </Helmet>
    </Box>
  );
};

export default Propose;
