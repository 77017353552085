import React from 'react';
import {Box, Heading, Text, Image, SimpleGrid, Card} from '@chakra-ui/react';
import Login from '../user/Login'

const Landing = () => {

  return (
    <Box>
      <Box bg='pink.100' color='black' py='10px' px={{ base: '10px', lg: '100px' }}>
        <SimpleGrid columns={{ md: 2 }} spacing='10px'>
          <Box textAlign="left">
            <Card p='10px' bgColor='pink.50' height='100%'>
              <Image
                src='https://firebasestorage.googleapis.com/v0/b/kobul-org.appspot.com/o/static%2Fcouple-djrks.webp?alt=media&token=20b3c3e4-e40c-4aa7-9e8f-2821f5df83f2'
                borderRadius='5px'
                height='330px'
                fit='cover'
                align='top'
                alt='Couple Image kobulapp.com'
              />
              <Heading as="h1" fontSize="xl" pt='10px'>
                KobulApp.com - বিয়ের জন্য পাত্র/পাত্রী খুজে পান একদম ফ্রি!
              </Heading>
              <Text fontSize="lg" pt='5px'>
                বায়োডাটা তৈরি করা, পছন্দের পাত্র/পাত্রী খোজা, চ্যাটিং করা, প্রস্তাব পাঠানো,
                ছবি ও যোগাযোগের তথ্য দেখা সবকিছু গোপনীয়তার সাথে ও একদম ফ্রি।
              </Text>
            </Card>
          </Box>

          <Login/>

        </SimpleGrid>
      </Box>

    </Box>
  );
};

export default Landing;
