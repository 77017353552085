import { Button, Card, HStack, Heading, Input, Select, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'

const Contact = ({ uData, updateDataToFirebase }) => {

  const [contactPersonData, setContactPersonData] =useState('')
  const [contactMobileData, setContactMobileData] = useState('');

  const [noEdit, setNoEdit] = useState(true);

  useEffect(() => {
    if (uData) {
      const {
        contactPerson = '', contactMobile = '',
      } = uData;
      setContactPersonData(contactPerson);
      setContactMobileData(contactMobile);
    }
  }, [uData]);


  const handleSave = () => {
    const updatedData = {
        contactPerson: contactPersonData,
        contactMobile: contactMobileData,
    };
    updateDataToFirebase(updatedData);
    setNoEdit(true);
  };

  return (
    <Card align='center' bgColor='pink.50' p='10px'>
      <Heading>যোগাযোগের তথ্য</Heading>
      <Card p='10px' bgColor='yellow.300'>
        যোগাযোগের তথ্য অতি গোপনীয়তার সাথে সংরক্ষন করা হয়।
        যদি আপনি কোনো সাক্ষাৎ এর প্রস্তাব এ সম্মতি প্রদান করেন
        কেবলমাত্র সেই ব্যাক্তি এই তথ্যগুলো দেখতে পাবে
      </Card>
      <Table size='sm'>
        <Tbody>
          <Tr>
            <Td><Text>যার মোবাইল</Text></Td>
            <Td>
              <Select
                value={contactPersonData}
                isDisabled={noEdit}
                onChange={(e) => setContactPersonData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="নিজ">নিজ</option>
                <option value="অভিভাবক">অভিভাবক</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>মোবাইল</Text></Td>
            <Td>
              <Input 
                value={contactMobileData} 
                isDisabled={noEdit}
                onChange={(e)=> setContactMobileData(e.target.value)} 
                _disabled={{color: 'black', opacity: 1,}}
                placeholder='017....'
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <HStack pt='10px'>
        {!noEdit && (
          <Button onClick={() => setNoEdit(true)} colorScheme='pink'>
            Cancel
          </Button>
        )}
        {noEdit && (
          <Button onClick={() => setNoEdit(false)} colorScheme='pink'>
            Edit
          </Button>
        )}
        {!noEdit && (
          <Button onClick={handleSave} colorScheme='green'>
            Save
          </Button>
        )}
      </HStack>
    </Card>
  );
}

export default Contact