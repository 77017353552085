import { useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase/initFirebase";
import {collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';


const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [userLoading, setUserLoading] = useState(true);
  const [uid, setUid] = useState();
  const [idsUnderMe, setIdsUnderMe] = useState();
  const [uData, setUData] = useState();
  const [noOwnId, setNoOwnId] = useState(false);
  const [reloadProfiles, setReloadProfiles] = useState(false);

  const [senderConversationsLength, setSenderConversationsLength] = useState([]);
  const [receiverConversationsLength, setReceiverConversationsLength] = useState([]);
  const [conversationsLength, setConversationsLength] = useState([]);

  const [reloadEverything, setReloadEverything] = useState(false);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setUserLoading(false);
    });
    return unsubscribe;
  }, []); 

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        if (currentUser) {
          const docRef = doc(db, 'users', currentUser.uid);
          const docSnap = await getDoc(docRef);
  
          if (docSnap.exists() && docSnap.data().ownId !== undefined) {
            setUid(docSnap.data().ownId);
            setIdsUnderMe(docSnap.data().othersId)
          } else {
            setNoOwnId(true);
          }
        }
      } catch (error) {
        console.error('Error fetching uid', error);
      }
    };
    fetchDoc();
  }, [currentUser, reloadEverything]);

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        if (uid) {
          const docRef = doc(db, 'profile', uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setUData(docSnap.data());
          }
        }
      } catch (error) {
        console.error('Error fetching uData', error);
      }
    };
    fetchDoc();
  }, [uid, reloadEverything, reloadProfiles]);

  useEffect(() => {
    if(currentUser) {
      const fetchConversations = async () => {
        try {
          const senderQuery = query(
            collection(db, 'convRef'),
            where('p1Uid', '==', currentUser.uid),
            where('lastMessageSenderUid', '!=', currentUser.uid)
          );
          const senderUnsubscribe = onSnapshot(senderQuery, (senderSnapshot) => {
            setSenderConversationsLength(senderSnapshot.docs.length);
          });

          const receiverQuery = query(
            collection(db, 'convRef'),
            where('p2Uid', '==', currentUser.uid),
            where('lastMessageSenderUid', '!=', currentUser.uid)
          );
          const receiverUnsubscribe = onSnapshot(receiverQuery, (receiverSnapshot) => {
            setReceiverConversationsLength(receiverSnapshot.docs.length);
          });

          return () => {
            senderUnsubscribe();
            receiverUnsubscribe();
          };
        } catch (error) {
          console.error('Error fetching conversations:', error);
        }
      };
      fetchConversations();
    }
  }, [currentUser, reloadEverything]);

  useEffect(() => {
    setConversationsLength(senderConversationsLength+receiverConversationsLength);
  }, [senderConversationsLength, receiverConversationsLength]);

  return (
    <AuthContext.Provider 
      value={{ currentUser, userLoading, uid, idsUnderMe, uData, noOwnId,
              reloadProfiles, setReloadProfiles, setReloadEverything, conversationsLength 
            }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
