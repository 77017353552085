import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, Button, Modal, ModalOverlay, ModalContent, Image, HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { db } from '../../firebase/initFirebase';
import { collection, query, getDocs, doc, updateDoc, getDoc, orderBy, where } from 'firebase/firestore';
import { AuthContext } from '../../context/AuthContext';

const ProposeReceived = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [dataChanged, setDataChanged] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pData, setPData] = useState(null);
  const [photoModal, setPhotoModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);

  useEffect(() => {
    const fetchRequests = async () => {
      const requestsQuery = query(
        collection(db, 'request'),
        where('receiverUid', '==', currentUser.uid),
        orderBy('timestamp', 'desc')
      );
      const requestsSnapshot = await getDocs(requestsQuery);
      const requestsData = [];
      requestsSnapshot.forEach((doc) => {
        requestsData.push({ id: doc.id, ...doc.data() });
      });
      setRequests(requestsData);
      setLoading(false);
    };
    if (currentUser) {
      fetchRequests();
    }
  }, [currentUser, dataChanged]); // Add dataChanged to dependencies array

  const handleAccept = async (requestId) => {
    const updatedData = {
      status: 'accepted'
    };
    try {
      const docRef = doc(db, 'request', requestId);
      await updateDoc(docRef, updatedData );
    } catch (error) {
      console.log(error);
    }
    setDataChanged(prevState => !prevState); // Trigger re-render
  };

  const handleReject = async (requestId) => {
    const updatedData = {
      status: 'rejected'
    };
    try {
      const docRef = doc(db, 'request', requestId);
      await updateDoc(docRef, updatedData );
    } catch (error) {
      console.log(error);
    }
    setDataChanged(prevState => !prevState); // Trigger re-render
  };

  const openPhotoModal = (pid) => {
    setPData(null);
    const fetchDoc = async () => {
      try {
          const docRef = doc(db, 'profile', pid);
          const docSnap = await getDoc(docRef);
  
          if (docSnap.exists()) {
            setPData(docSnap.data());
          } 
        } catch (error) {
        console.error('Error fetching document:', error);
      }
    };
    
  fetchDoc();
  setIsModalOpen(true);
  setPhotoModal(true);
};

const openContactModal = (pid) => {
  setPData(null);
  const fetchDoc = async () => {
    try {
        const docRef = doc(db, 'profile', pid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPData(docSnap.data());
        } 
      } catch (error) {
      console.error('Error fetching document:', error);
    }
  };
  
fetchDoc();
setIsModalOpen(true);
setContactModal(true);
};

const closeModal = () => {
  setIsModalOpen(false);
  setContactModal(false);
  setPhotoModal(false);
};

  return (
    <Box>
      <Box>
      {loading ? (
        <Text>Loading...</Text>
      ) : (
        <Box>
          {requests.map(request => (
            <Box key={request.id} borderBottom='1px' borderColor='pink.200' py='5px'>
                <Box>
                  <Text mb='5px'>
                    <Link to={`/id/${request.senderId}`} target="_blank" rel="noopener noreferrer">
                      <Text color='pink.500' as="span"><strong>{request.senderId}</strong></Text>
                    </Link>{" "}
                    আইডি থেকে{" "} 
                    <Link to={`/id/${request.receiverId}`} target="_blank" rel="noopener noreferrer">
                      <Text as="span" color='pink.500'><strong>{request.receiverId}</strong></Text>
                    </Link>{" "}
                    আইডির জন্য
                    {request.type === 'contact' ? (
                      <strong>🤝সাক্ষাৎ করার </strong>
                      ) : <strong>📷ছবি দেখার </strong>
                    }
                    প্রস্তাব এসেছে
                  </Text>
                  <Button onClick={() => openPhotoModal(request.senderId)} colorScheme='pink' size='xs' variant='outline'>
                    ছবি দেখুন
                  </Button>
                  {request.status === 'accepted' ? (
                    <>
                      <Button size='xs' colorScheme='green' isDisabled mx='5px'>Accepted</Button>
                      <Text mt='5px'>
                        ধন্যবাদ, আপনি
                        {request.type === 'contact' ? (
                          <strong>🤝সাক্ষাৎ করার তথ্য দেখার </strong>
                          ) :  <strong>📷ছবি দেখার </strong>
                        } 
                        অনুমতি দিয়েছেন।
                      </Text>
                    </>
                  ) : request.status === 'rejected' ? (
                    <>
                      <Button size='xs' colorScheme='red' isDisabled mx='5px'>Rejected</Button>
                      <Text mt='5px'>আপনি তার প্রস্তাবটি বাতিল করেছেন।</Text>
                    </>
                  ) : 
                    <>
                      <Button onClick={() => handleAccept(request.id)} size='xs' colorScheme='green' mx='20px'>Accept</Button>
                      <Button onClick={() => handleReject(request.id)} size='xs' colorScheme='red'>Reject</Button>
                      <Text mt='5px'>দয়া করে উত্তর দিন।</Text>
                    </>
              }
                </Box>
            </Box>
          ))}
        </Box>
      )}
      <Modal isOpen={isModalOpen}>
        <ModalOverlay />
        <ModalContent>
          { pData && photoModal &&
            <Box align='center'>
              <HStack justifyContent='space-between'>
              <Text p='5px' fontSize='20px'>{pData.name}</Text>
              <Button colorScheme="pink" onClick={closeModal} borderRadius='0px'>
                Close
              </Button>
              </HStack>
              <Image src={pData.image} alt="Photo" w='300px' h='300px' fit='cover' borderRadius='5px' />
            </Box>
          }
          { pData && contactModal &&
            <Box align='center' >
              <HStack justifyContent='space-between'>
              <Text p='5px' fontSize='20px'>{pData.name}</Text>
              <Button colorScheme="pink" onClick={closeModal} borderRadius='0px'>
                Close
              </Button>
              </HStack>
              <Image src={pData.image} alt="Photo" w='150px' h='150px' fit='cover' />
              <Text p='5px' fontSize='20px'>যোগাযোগের তথ্য</Text>
              <Text>যোগাযোগকারীঃ {pData.contactPerson || '' }</Text>
              <Text>মোবাইলঃ {pData.contactMobile || '' }</Text>
            </Box>
          }
        </ModalContent>
      </Modal>
      </Box>
    </Box>
  );
};

export default ProposeReceived;
