import React, { useEffect, useState } from 'react';
import { Card, Table, Tbody, Tr, Td, Button, HStack, Text, Heading, Select } from '@chakra-ui/react';

const Family = ({ uData, updateDataToFirebase }) => {

  const [parentsData, setParentsData] = useState('');
  const [fatherProfessionData, setFatherProfessionData] = useState('');
  const [motherProfessionData, setMotherProfessionData] = useState('');
  const [brotherNumberData, setBrotherNumberData] = useState('');
  const [sisterNumberData, setSisterNumberData] = useState('');
  const [financialConditionData, setFinancialConditionData] = useState('');

  const [noEdit, setNoEdit] = useState(true);

  useEffect(() => {
    if (uData) {
      const {
        parents = '', fatherProfession = '', motherProfession = '', brotherNumber = '',
        sisterNumber = '', financialCondition = '',
      } = uData;
      setParentsData(parents);
      setFatherProfessionData(fatherProfession);
      setMotherProfessionData(motherProfession);
      setBrotherNumberData(brotherNumber);
      setSisterNumberData(sisterNumber);
      setFinancialConditionData(financialCondition);
    }
  }, [uData]);

  const handleSave = () => {
    const updatedData = {
      parents: parentsData,
      fatherProfession: fatherProfessionData,
      motherProfession: motherProfessionData,
      brotherNumber: brotherNumberData,
      sisterNumber: sisterNumberData,
      financialCondition: financialConditionData,
    };
    updateDataToFirebase(updatedData);
    setNoEdit(true);
  };

  return (
    <Card align='center' bgColor='pink.50' p='10px'>
      <Heading>পারিবারিক তথ্য</Heading>
      <Table size='sm'>
        <Tbody>
          <Tr>
            <Td><Text>পিতা মাতা</Text></Td>
            <Td>
              <Select
                value={parentsData}
                isDisabled={noEdit}
                onChange={(e) => setParentsData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="পিতা মাতা উভয় জীবিত">পিতা মাতা উভয় জীবিত</option>
                <option value="পিতা আছেন মাতা মৃত">পিতা আছেন মাতা মৃত</option>
                <option value="মাতা আছেন পিতা মৃত">মাতা আছেন পিতা মৃত</option>
                <option value="পিতা মাতা উভয় মৃত">পিতা মাতা উভয় মৃত</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>বাবার পেশা</Text></Td>
            <Td>
              <Select
                value={fatherProfessionData}
                isDisabled={noEdit}
                onChange={(e) => setFatherProfessionData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="ব্যবসা">ব্যবসা</option>
                <option value="পেশাজীবী">পেশাজীবী</option>
                <option value="চাকরি">চাকরি</option>
                <option value="প্রবাসী">প্রবাসী</option>
                <option value="কৃষিকাজ">কৃষিকাজ</option>
                <option value="অবসরপ্রাপ্ত">অবসরপ্রাপ্ত</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>মায়ের পেশা</Text></Td>
            <Td>
            <Select
                value={motherProfessionData}
                isDisabled={noEdit}
                onChange={(e) => setMotherProfessionData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="গৃহিনী">গৃহিনী</option>
                <option value="ব্যবসায়ী">ব্যবসায়ী</option>
                <option value="পেশাজীবী">পেশাজীবী</option>
                <option value="চাকরিজীবী">চাকরিজীবী</option>
                <option value="প্রবাসী">প্রবাসী</option>
                <option value="কৃষক">কৃষক</option>
                <option value="অবসরপ্রাপ্ত">অবসরপ্রাপ্ত</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>ভাইয়ের সংখ্যা</Text></Td>
            <Td>
            <Select
                value={brotherNumberData}
                isDisabled={noEdit}
                onChange={(e) => setBrotherNumberData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="ভাই নেই">ভাই নেই</option>
                <option value="১ ভাই">১ ভাই</option>
                <option value="২ ভাই">২ ভাই</option>
                <option value="৩ ভাই">৩ ভাই</option>
                <option value="৪ ভাই">৪ ভাই</option>
                <option value="৫ ভাই">৫ ভাই</option>
                <option value="৬ ভাই">৬ ভাই</option>
                <option value="৭ বা ততোধিক">৭ বা ততোধিক</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>বোনের সংখ্যা</Text></Td>
            <Td>
              <Select
                value={sisterNumberData}
                isDisabled={noEdit}
                onChange={(e) => setSisterNumberData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="বোন নেই">বোন নেই</option>
                <option value="১ বোন">১ বোন</option>
                <option value="২ বোন">২ বোন</option>
                <option value="৩ বোন">৩ বোন</option>
                <option value="৪ বোন">৪ বোন</option>
                <option value="৫ বোন">৫ বোন</option>
                <option value="৬ বোন">৬ বোন</option>
                <option value="৭ বা ততোধিক">৭ বা ততোধিক</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>অর্থনৈতিক অবস্থা</Text></Td>
            <Td>
            <Select
                value={financialConditionData}
                isDisabled={noEdit}
                onChange={(e) => setFinancialConditionData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="ধনী">ধনী</option>
                <option value="সচ্ছল">সচ্ছল</option>
                <option value="মধ্যবিত্ত">মধ্যবিত্ত</option>
                <option value="দরিদ্র">দরিদ্র</option>
              </Select>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <HStack pt='10px'>
        {!noEdit && (
          <Button onClick={() => setNoEdit(true)} colorScheme='pink'>
            Cancel
          </Button>
        )}
        {noEdit && (
          <Button onClick={() => setNoEdit(false)} colorScheme='pink'>
            Edit
          </Button>
        )}
        {!noEdit && (
          <Button onClick={handleSave} colorScheme='green'>
            Save
          </Button>
        )}
      </HStack>
    </Card>
  );
};

export default Family;
