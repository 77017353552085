import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Box, Button, Card, Heading, Input, Text } from '@chakra-ui/react'; 
import { auth } from '../../firebase/initFirebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Login = () => {
  const { currentUser } = useContext(AuthContext);
  const [signinButtonLoading, setSigninButtonLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const signInWithEmailPassword = () => {
    setSigninButtonLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setEmail('');
        setPassword('');
        setError(null);
      })
      .catch(error => {
        setError(error.message);
      })
      .finally(() => {
        setSigninButtonLoading(false);
        window.location.reload();
      });
  };

  return (
    
    <Box p='10px' bgColor='pink.50' align='center' borderRadius='5px'>
      { !currentUser && (
      <Card p='10px' w='320px'>
        <Heading my='20px' color='pink.500'>👍 Login</Heading>
        <Box>
          <Text align='left' color='grey.200'>ইমেইল</Text>
          <Input type="email" placeholder="Type your email" value={email} onChange={(e) => setEmail(e.target.value)} />

          <Text align='left' color='grey.200' mt='10px'>পাসওয়ার্ড</Text>
          <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />

          <Button onClick={signInWithEmailPassword} isLoading={signinButtonLoading} w='300px' colorScheme='pink' mt='10px'>
            লগ ইন
          </Button>

          <Link to='/forgotpassword'>
            <Text align='right' color='blue.400' mt='10px'>Forgot Password?</Text>
          </Link>

          <Text color='grey' mt='20px'>কবুল অ্যাপে একাউন্ট নেই?</Text>
          <Link to='/join'>
            <Button colorScheme='pink' variant='outline' mt='10px' mb='30px'>একাউন্ট তৈরি করুন</Button>
          </Link>
          {error && <Box mt={2} color="red">{error}</Box>}
        </Box>
      </Card>
      )}
      { currentUser && (
        <Card p='10px' w='320px'>
          <Heading mt='20px'>✅</Heading>
          <Text color='grey' mt='20px'>আপনি সফল ভাবে লগইন করেছেন। আপনার বায়োডাটা দেখুন</Text>
          <Link to='/profile'>
            <Button colorScheme='pink' variant='outline' >বায়োডাটা</Button>
          </Link>
        </Card>
      )}
      <Helmet>
        <title>Kobul Login - Create, Search Wedding Biodata for free</title>
        <meta name="description" content="View wedding biodata, send proposal, search your desired person & create your own biodata for free" />
      </Helmet>
    </Box>
  );
};

export default Login;
