import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getMessaging } from "@firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyA0nx57XtzloBrHOVMXtzjC1jFqqFBtlfk",
    authDomain: "kobul-org.firebaseapp.com",
    projectId: "kobul-org",
    storageBucket: "kobul-org.appspot.com",
    messagingSenderId: "683549215345",
    appId: "1:683549215345:web:d4940ba76b275fcd6de9d0"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const messaging = getMessaging(app);

export default app;