import React, { useEffect, useState } from 'react';
import { Card, Table, Tbody, Tr, Td, Button, HStack, Text, Heading, Textarea, Select } from '@chakra-ui/react';

const Professional = ({ uData, updateDataToFirebase }) => {

  const [educationDegreeData, setEducationDegreeData] = useState('');
  const [educationInstituteData, setEducationInstituteData] = useState('');
  const [professionData, setProfessionData] = useState('');
  const [professionDetailsData, setProfessionDetailsData] = useState('');
  const [monthlyIncomeData, setMonthlyIncomeData] = useState('');

  const [noEdit, setNoEdit] = useState(true);

  useEffect(() => {
    if (uData) {
      const {
        educationDegree = '', educationInstitute = '', profession = '', professionDetails = '',
        monthlyIncome = '',
      } = uData;
      setEducationDegreeData(educationDegree);
      setEducationInstituteData(educationInstitute);
      setProfessionData(profession);
      setProfessionDetailsData(professionDetails);
      setMonthlyIncomeData(monthlyIncome);
    }
  }, [uData]);

  const handleSave = () => {
    const updatedData = {
      educationDegree: educationDegreeData,
      educationInstitute: educationInstituteData,
      profession: professionData,
      professionDetails: professionDetailsData,
      monthlyIncome: monthlyIncomeData,
    };
    updateDataToFirebase(updatedData);
    setNoEdit(true);
  };

  return (
    <Card align='center' bgColor='pink.50' p='10px'>
      <Heading>পেশাগত তথ্য</Heading>
      <Table size='sm'>
        <Tbody>
          <Tr>
            <Td><Text>শিক্ষাগত যোগ্যতা</Text></Td>
            <Td>
              <Select
                value={educationDegreeData}
                isDisabled={noEdit}
                onChange={(e) => setEducationDegreeData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="মাস্টার্স/সমমান">মাস্টার্স/সমমান</option>
                <option value="অনার্স/সমমান">অনার্স/সমমান</option>
                <option value="ডিগ্রি/সমমান">ডিগ্রি/সমমান</option>
                <option value="এইচএসসি">এইচএসসি</option>
                <option value="এসএসসি">এসএসসি</option>
                <option value="প্রাথমিক শিক্ষা">প্রাথমিক শিক্ষা</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>শিক্ষা প্রতিষ্ঠান</Text></Td>
            <Td>
              <Textarea 
                value={educationInstituteData} 
                isDisabled={noEdit} 
                onChange={(e)=> setEducationInstituteData(e.target.value)} 
                _disabled={{color: 'black', opacity: 1,}} 
              />
            </Td>
          </Tr>
          <Tr>
            <Td><Text>পেশা</Text></Td>
            <Td>
              <Select
                value={professionData}
                isDisabled={noEdit}
                onChange={(e) => setProfessionData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="স্টুডেন্ট">স্টুডেন্ট</option>
                <option value="ব্যবসা">ব্যবসা</option>
                <option value="স্বনির্ভর">স্বনির্ভর</option>
                <option value="বেসরকারি চাকরি">বেসরকারি চাকরি</option>
                <option value="সরকারি চাকরি">সরকারি চাকরি</option>
                <option value="প্রবাসী">প্রবাসী</option>
                <option value="কৃষি/অন্যান্য">কৃষি/অন্যান্য</option>
                <option value="গৃহিনী">গৃহিনী</option>
                <option value="কিছু করছি না">কিছু করছি না</option>
              </Select>
            </Td>
          </Tr>
          <Tr>
            <Td><Text>পেশার বিবরন</Text></Td>
            <Td>
              <Textarea 
                value={professionDetailsData} 
                isDisabled={noEdit} 
                onChange={(e)=> setProfessionDetailsData(e.target.value)} 
                _disabled={{color: 'black', opacity: 1,}} 
              />
            </Td>
          </Tr>
          <Tr>
            <Td><Text>মাসিক ইনকাম</Text></Td>
            <Td>
            <Select
                value={monthlyIncomeData}
                isDisabled={noEdit}
                onChange={(e) => setMonthlyIncomeData(e.target.value)}
                _disabled={{ color: 'black', opacity: 1 }}
              >
                <option value="">সিলেক্ট করুন</option>
                <option value="০ - ২০ হাজার">০ - ২০ হাজার</option>
                <option value="২১ - ৪০ হাজার">২১ - ৪০ হাজার</option>
                <option value="৪১ - ৬০ হাজার">৪১ - ৬০ হাজার</option>
                <option value="৬১ - ৯৯ হাজার">৬১ - ৯৯ হাজার</option>
                <option value="লক্ষাধিক">লক্ষাধিক</option>
              </Select>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <HStack pt='10px'>
        {!noEdit && (
          <Button onClick={() => setNoEdit(true)} colorScheme='pink'>
            Cancel
          </Button>
        )}
        {noEdit && (
          <Button onClick={() => setNoEdit(false)} colorScheme='pink'>
            Edit
          </Button>
        )}
        {!noEdit && (
          <Button onClick={handleSave} colorScheme='green'>
            Save
          </Button>
        )}
      </HStack>
    </Card>
  );
};

export default Professional;
