import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Box, Button, Card, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { collection, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore';
import { db } from '../../firebase/initFirebase';
import LoginScreen from '../utility/LoginScreen'


const ChatList = () => {
  const { currentUser } = useContext(AuthContext);

  const [senderConversations, setSenderConversations] = useState([]);
  const [receiverConversations, setReceiverConversations] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [conversationsLoading, setConversationsLoading] = useState(true);

  const [lastSenderConversation, setlastSenderConversation] = useState([]);
  const [lastReceiverConversation, setLastReceiverConversation,] = useState([]);
  const itemPerPage = 8;

  useEffect(() => {
    if(currentUser) {
      const fetchConversations = async () => {
        setConversationsLoading(true)
        try {
          const senderQuery = query(
            collection(db, 'convRef'),
            where('p1Uid', '==', currentUser.uid),
            orderBy('lastMessageTime', 'desc'),
            limit(itemPerPage)
          );
          const senderSnapshot = await getDocs(senderQuery);
            const senderConvs = senderSnapshot.docs.map(doc => ({
              partnerId: doc.data().p2Id,
              partnerAvatar: doc.data().p2Avatar,
              partnerName: doc.data().p2Name,
              convId: doc.data().convId,
              lastMessage: doc.data().lastMessage,
              lastMessageSenderUid: doc.data().lastMessageSenderUid,
              lastMessageTime: doc.data().lastMessageTime
            }))
            setlastSenderConversation(senderSnapshot.docs[senderSnapshot.docs.length - 1]);
            setSenderConversations([...senderConvs]);
          
          const receiverQuery = query(
            collection(db, 'convRef'),
            where('p2Uid', '==', currentUser.uid),
            orderBy('lastMessageTime', 'desc'),
            limit(itemPerPage)
          );
          const receiverSnapshot = await getDocs(receiverQuery);
            const receiverConvs = receiverSnapshot.docs.map(doc => ({
              partnerId: doc.data().p1Id,
              partnerName: doc.data().p1Name,
              partnerAvatar: doc.data().p1Avatar,
              convId: doc.data().convId,
              lastMessage: doc.data().lastMessage,
              lastMessageSenderUid: doc.data().lastMessageSenderUid,
              lastMessageTime: doc.data().lastMessageTime
            }));
            setLastReceiverConversation(receiverSnapshot.docs[receiverSnapshot.docs.length - 1]);
            setReceiverConversations([...receiverConvs]);

        } catch (error) {
          console.error('Error fetching conversations:', error);
        } finally {
          setConversationsLoading(false);
        }
      };
      fetchConversations();
    }
  }, [currentUser]);

  const fetchMoreConversations = async () => {
    setConversationsLoading(true)
    try {
      if (lastSenderConversation) {
        const senderQuery = query(
          collection(db, 'convRef'),
          where('p1Uid', '==', currentUser.uid),
          orderBy('lastMessageTime', 'desc'),
          startAfter(lastSenderConversation),
          limit(itemPerPage)
        );
        const senderSnapshot = await getDocs(senderQuery);
          const senderConvs = senderSnapshot.docs.map(doc => ({
            partnerId: doc.data().p2Id,
            partnerAvatar: doc.data().p2Avatar,
            partnerName: doc.data().p2Name,
            convId: doc.data().convId,
            lastMessage: doc.data().lastMessage,
            lastMessageSenderUid: doc.data().lastMessageSenderUid,
            lastMessageTime: doc.data().lastMessageTime
          }))
          setlastSenderConversation(senderSnapshot.docs[senderSnapshot.docs.length - 1]);
        setSenderConversations([...senderConvs]);
      }
      
      if (lastReceiverConversation) {
        const receiverQuery = query(
          collection(db, 'convRef'),
          where('p2Uid', '==', currentUser.uid),
          orderBy('lastMessageTime', 'desc'),
          startAfter(lastReceiverConversation),
          limit(itemPerPage)
        );
        const receiverSnapshot = await getDocs(receiverQuery);
          const receiverConvs = receiverSnapshot.docs.map(doc => ({
            partnerId: doc.data().p1Id,
            partnerName: doc.data().p1Name,
            partnerAvatar: doc.data().p1Avatar,
            convId: doc.data().convId,
            lastMessage: doc.data().lastMessage,
            lastMessageSenderUid: doc.data().lastMessageSenderUid,
            lastMessageTime: doc.data().lastMessageTime
          }));
          setLastReceiverConversation(receiverSnapshot.docs[receiverSnapshot.docs.length - 1]);
        setReceiverConversations([...receiverConvs]);
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setConversationsLoading(false);
    }
  };

  useEffect(() => {
    const bothConversations = [ ...senderConversations, ...receiverConversations];
    bothConversations.sort((a, b) => {
      return b.lastMessageTime - a.lastMessageTime;
    });
    setConversations(bothConversations);
  }, [senderConversations, receiverConversations]);

  return (
    <Box  bg='pink.100' py='10px' px={{base:'10px', lg:'100px'}} align='center'>
      {!currentUser && 
        <LoginScreen />
      }
      { currentUser &&
      <>
        <Heading as="h2" fontSize="2xl" textAlign="center" pb='10px'>
          ম্যাসেজ সমূহ
        </Heading>
        <Box borderRadius='5px'>
          {conversationsLoading && 
            <Box align='center' p='10px'>
              <Button isLoading colorScheme='pink'>Loading</Button>
            </Box>
          }
          {!conversationsLoading &&  conversations.length < 1 && <Text>আপনার কোনো ম্যাসেজ নাই</Text>}
          {conversations.map(conversation => (
            <Box key={conversation.convId}>
              <Link to={`/chat/${conversation.partnerId}`}>
              <Card p='5px' mb='5px'>
                <HStack justify='left'>
                <Image src={conversation.partnerAvatar || 'https://firebasestorage.googleapis.com/v0/b/kobul-org.appspot.com/o/static%2Favatar2.png?alt=media&token=fb83d8b9-83ed-492e-a145-3215b79d1310'}
                w='40px' h='40px' borderRadius='20px' fit='cover' />
                <VStack spacing='0px' align='flex-start'
                  textColor={conversation.lastMessageSenderUid === currentUser.uid ? "grey" : "black"}
                >
                  <Text 
                    fontSize='sm'
                    as={conversation.lastMessageSenderUid === currentUser.uid ? "p" : "b"}>
                    {conversation.partnerName || conversation.partnerId}
                  </Text>
                  <Text
                    noOfLines={1}
                    fontSize='xs'
                    as={conversation.lastMessageSenderUid === currentUser.uid ? "p" : "b"}
                  >
                    {conversation.lastMessage}
                  </Text>
                </VStack>
                </HStack>
              </Card>
              </Link>
            </Box>
          ))}
        </Box>
        <Button onClick={fetchMoreConversations}>Load More</Button>
      </>
      }
      <Helmet>
        <title>Kobul Chat - Chat privately with bride and groom</title>
        <meta name="description" content="You can chat with your partner completely private and secured inside kobul app" />
      </Helmet>
    </Box>
  );
};

export default ChatList;
